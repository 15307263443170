import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NotFound.scss";
import Logo from "src/shared/components/logo/Logo";

const NotFound = () => {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/", {
      replace: true,
    });
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.info}>
          <span className={styles.title}>404 - Not Found!</span>
          <button className={styles.button} onClick={handleClick}>
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
