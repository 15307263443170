import React, { useContext, useEffect, useState } from "react";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import styles from "./CustomConversions.scss";
import StandardLayout from "src/shared/components/standard-layout/StandardLayout";
import { useSelector } from "react-redux";
import { getDrawerOpenStatus } from "../state/clientSelector";
import useAuthService from "src/shared/hooks/useAuthService";
import apiService, { HTTPMethod } from "src/services/api-service";
import {
  getDateRange,
  AutoDateRange,
  DateRange
} from "src/shared/components/datepicker/Datepicker";
import {
  ReportData,
  ReportContextType,
  defaultReportcontext, Insight
} from "../state/reportTypes";
import {
  currencyFormatter,
  numberWithCommas
} from "src/shared/helpers/formatters";

import config from "config";
import CustomConversionsFilters from "./components/custom-conversion-filters/CustomConversionsFilters";
import Skeleton from "@mui/material/Skeleton";
import { Level } from "../report/components/levelpicker/LevelPicker";
import style from "src/app/client/report/Report.scss";
import { filterAccounts } from "src/shared/helpers/Filters";
import CsvReport from "src/shared/components/CsvReport";

export const CustomConversionsContext =
  React.createContext<ReportContextType>(defaultReportcontext);

const CustomCoversionsWrapper = () => {
  const { currentUser, redirectIfNotLoggedIn, isAuthenticating } =
    useAuthService();
  const [reportData, setReportData] = React.useState<ReportData | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    ...getDateRange(AutoDateRange.LAST_28_DAYS),
    range: AutoDateRange.LAST_28_DAYS
  });
  const [level, setLevel] = useState<Level>(Level.CAMPAIGN);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectableAccounts, setSelectableAccounts] = useState<
    { key: string; value: string }[]
  >([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectableCampaigns, setSelectableCampaigns] = useState<
    { group: string, inner: { key: string; value: string }[] }[]
  >([]);
  const [selectedAdsets, setSelectedAdsets] = useState<string[]>([]);
  const [selectableAdsets, setSelectableAdsets] = useState<
    { group: string, inner: { key: string; value: string }[] }[]
  >([]);
  const [selectedCustomConversions, setSelectedCustomConversions] = useState<
    string[]
  >([]);
  const [selectableCustomConversions, setSelectableCustomConversions] = useState<
    { group: string, inner: { key: string; value: string }[] }[]
  >([]);

  useEffect(() => {
    if (dateRange !== null && !isAuthenticating && currentUser.readToken) {
      setIsLoading(true);
      apiService
        .fetch(`${config.reactAppBackendUrl}/report`, {
          preserveEndpoint: true,
          headers: {
            Authorization: `Bearer ${currentUser?.readToken}`
          },
          body: JSON.stringify({
            lvl: level,
            start: dateRange?.startDate?.toISOString().substring(0, 10),
            end: dateRange?.endDate?.toISOString().substring(0, 10),
            use_custom_conversions: true
          }),
          method: HTTPMethod.POST
        })
        .then((data) => {
          let reportData = new ReportData(data["data"]);
          setReportData(reportData);
          setSelectedAccounts(
            reportData
              .accounts
              .sort((a, b) => a.value > b.value ? 1 : -1)
              .map((item) => item.key)
          );
          setSelectableAccounts(reportData.accounts);
          filterAccounts(
            reportData.accounts.map((item) => item.key),
            setSelectedAccounts,
            setSelectedCampaigns,
            setSelectableCampaigns,
            setSelectedAdsets,
            setSelectableAdsets,
            setSelectedCustomConversions,
            setSelectableCustomConversions,
            reportData
          );
          setIsLoading(false);
        });
    }
  }, [
    dateRange?.startDate?.toISOString().substring(0, 10),
    dateRange?.endDate?.toISOString().substring(0, 10),
    level,
    isAuthenticating,
    currentUser.readToken
  ]);

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <CustomConversionsContext.Provider
      value={{
        selectedAccounts,
        selectableAccounts,
        selectedCampaigns,
        selectableCampaigns,
        selectedAdsets,
        setSelectedAdsets,
        selectableAdsets,
        setSelectableAdsets,
        selectedCustomConversions,
        selectableCustomConversions,
        reportData,
        previousData: null,
        setSelectedAccounts,
        setSelectableAccounts,
        setSelectedCampaigns,
        setSelectableCampaigns,
        setSelectedCustomConversions,
        setSelectableCustomConversions,
        isLoading,
        setIsLoading,
        dateRange,
        setDateRange,
        level,
        setLevel
      }}
    >
      <CustomCoversions />
    </CustomConversionsContext.Provider>
  );
};

const CustomCoversions = () => {
  const {
    reportData,
    isLoading,
    selectedCustomConversions
  } = useContext(CustomConversionsContext);

  const isDrawerOpen = useSelector(getDrawerOpenStatus);

  let columns = [
    {
      id: "account_id",
      displayName: "Account Id"
    }, {
      id: "account",
      displayName: "Account"
    }, {
      id: "campaign_id",
      displayName: "Campaign Id"
    }, {
      id: "campaign",
      displayName: "Campaign"
    }, {
      id: "conversion_type",
      displayName: "Conversion Type"
    }, {
      id: "conversions",
      displayName: "Conversions"
    }, {
      id: "conversions_value",
      displayName: "Conversion Value"
    }
  ];

  const datas = reportData?.insights
    .filter((insight) => selectedCustomConversions.includes(insight.custom_conversion_id))
    .map((data) => {
      return {
        account_id: data.account_id,
        account: data.account,
        campaign_id: data.campaign_id,
        campaign: data.campaign,
        ad_set_id: data.ad_set_id,
        ad_set: data.ad_set,
        conversion_type: data.custom_conversion,
        conversions: data.conversions.toString(),
        conversions_value: data.conversions_value.toString(),
        ad_spend: data.ad_spend.toString(),
        clicks: data.clicks.toString(),
        impressions: data.impressions.toString(),
        reach: data.reach.toString()
      };
    });

  const mainContent =
    reportData !== null && !isLoading ? (
      <div className={style.report}>
        <CustomConversionsFilters />
        <ConversionsTable />
        <CsvReport
          isLoading={isLoading}
          columns={columns}
          datas={datas}
        />

      </div>
    ) : (
      <Loader />
    );

  return (
    <StandardLayout mainContent={mainContent} isDrawerOpen={isDrawerOpen} />
  );
};

const Loader = () => {
  return (
    <div className={style.report}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          width: "100%",
          height: "96px",
          borderRadius: "16px",
          padding: "10px",
          marginBottom: "20px"
        }}
      ></Skeleton>

      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          height: "80%",
          width: "100%",
          borderRadius: "16px"
        }}
      />
    </div>
  );
};

const columns: GridColDef[] = [
  { field: "name", headerName: "Conversion type", flex: 2 },
  {
    field: "conversions_value",
    headerName: "Conversions value",
    flex: 1,
    renderCell: (row) => currencyFormatter.format(row.value)
  },
  {
    field: "conversions",
    headerName: "Conversions",
    flex: 1,
    renderCell: (row) => numberWithCommas(row.value)
  },
  {
    field: "average_conversion_value",
    headerName: "Average Conversion Value",
    flex: 1,
    renderCell: (row) => currencyFormatter.format(row.value)
  }
];

const ConversionsTable = () => {
  const {
    reportData,
    selectedCustomConversions
  } = useContext(CustomConversionsContext);

  if (!reportData) {
    return null;
  }

  let filteredItems: Insight[] = reportData.insights.filter((insight) => {
    return selectedCustomConversions.includes(insight.custom_conversion_id);
  });

  const rawTableData: Map<string, {
    conversions_value: number;
    conversions: number;
  }> = new Map();

  const grandTotal = {
    conversions_value: 0,
    conversions: 0
  };
  filteredItems.forEach(
    ({ custom_conversion, conversions_value, conversions }) => {
      if (custom_conversion) {
        let conv_value = conversions_value !== null ? conversions_value : 0;
        let conv = conversions !== null ? conversions : 0;
        grandTotal.conversions_value += conv_value;
        grandTotal.conversions += conv;
        if (rawTableData.has(custom_conversion)) {
          const currentData = rawTableData.get(custom_conversion);
          // @ts-ignore
          currentData.conversions_value += conv_value;
          // @ts-ignore
          currentData.conversions += conv;
        } else {
          rawTableData.set(custom_conversion, {
            conversions_value: conv_value,
            conversions: conv
          });
        }
      }
    }
  );

  const tableData = [...rawTableData].map(([name, { conversions_value, conversions }], index) => {
    return {
      id: index + 1,
      name,
      conversions_value,
      conversions,
      average_conversion_value:
        conversions_value / (conversions || 1)
    };
  });

  const getCustomFooter = () => (
    <div className={styles.footer}>
      <div style={{ flex: 2 }}>Grand total</div>
      <div style={{ flex: 1 }}>
        {currencyFormatter.format(grandTotal.conversions_value)}
      </div>
      <div style={{ flex: 1 }}>{numberWithCommas(grandTotal.conversions)}</div>
      <div style={{ flex: 1 }}>
        {currencyFormatter.format(
          grandTotal.conversions_value / grandTotal.conversions
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.conversionTable}>
      <DataGrid
        rows={tableData}
        columns={columns}
        components={{ Footer: getCustomFooter }}
      />
    </div>
  );
};

export default CustomCoversionsWrapper;
