import React from "react";
import { styled } from "@mui/material/styles";

import Drawer from "src/shared/components/standard-layout/components/drawer/Drawer";
import AppBar from "src/shared/components/standard-layout/components/appbar/AppBar";

import styles from "./StandardLayout.scss";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{}>(({ theme }) => ({
  flexGrow: 1,
  marginLeft: 81,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

type StandardLayoutProps = {
  isDrawerOpen: boolean;
  mainContent: React.ReactNode;
};

const StandardLayout = (props: StandardLayoutProps) => {
  const { isDrawerOpen, mainContent } = props;
  const backgroundColor = isDrawerOpen ? "rgba(0,0,0,0.0)" : "rgba(0,0,0,0.5)";
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Drawer />
      <div className={styles.container}>
        <Main className={styles.standardLayout}>{mainContent}</Main>
      </div>
    </div>
  );
};

export default StandardLayout;
