import React from "react";

import WhatWeDo1 from "static/images/landing/what-we-do/1.min.svg";
import WhatWeDo2 from "static/images/landing/what-we-do/2.min.svg";
import WhatWeDo3 from "static/images/landing/what-we-do/3.min.svg";

import styles from "./../Landing.scss";
import classNames from "classnames";

const WhatWeDo = () => {
  return (
    <div
      className={classNames(
        styles.paddedSection,
        styles.paddedSectionLightBlue
      )}
    >
      <div
        className={classNames(
          styles.sectionHeader,
          styles.sectionHeaderCenter,
          styles.sectionHeaderDarkBlue
        )}
      >
        What we do
      </div>

      <div className={styles.whatWeDo}>
        <div className={styles.item}>
          <div className={styles.image}>
            <WhatWeDo1 />
          </div>
          <hr style={{ width: "95%" }} />
          <div className={styles.text}>
            Our software allows you to see the exact ROI of your Facebook,
            Instagram, email and texting campaigns at the in-store level.
            Helping you spend your money better and grow your business.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.image}>
            <WhatWeDo2 />
          </div>
          <hr style={{ width: "95%" }} />
          <div className={styles.text}>
            We also allow you to place ads back in front of your existing
            customers inside your POS system. Leading to an increased lifetime
            value and business growth.
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.image}>
            <WhatWeDo3 />
          </div>
          <hr style={{ width: "95%" }} />
          <div className={styles.text}>
            Lastly, especially with co-op dollars, we can help you target the
            very brand people purchased, leading to more in-store purchases and
            increased customer loyalty for that brand.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
