import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

import React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";

import LogoutIcon from "@mui/icons-material/Logout";
import TableViewIcon from "@mui/icons-material/TableView";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosSharp";

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import useAuthService from "src/shared/hooks/useAuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDrawerOpenStatus } from "src/app/client/state/clientSelector";

import styles from "./Drawer.scss";
import classNames from "classnames";
import { toggleDrawer } from "src/app/client/state/clientSlice";
import { Image } from "src/app/client/report/components/AnimatedLogo";

const drawerWidth = 280;
const DRAWER_BACKGROUND = "#101827";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `80px`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  width: `100%`,
  backgroundColor: DRAWER_BACKGROUND,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  color: "#fff",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: DRAWER_BACKGROUND,
      color: "#fff",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: DRAWER_BACKGROUND,
      color: "#fff",
    },
  }),
  backgroundColor: DRAWER_BACKGROUND,
}));

const SideDrawer = () => {
  const { logoutUser, isUserPermissionTo } = useAuthService();
  const isDrawerOpen = useSelector(getDrawerOpenStatus);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const updateDrawer = () => {
    dispatch(toggleDrawer(!isDrawerOpen));
  };

  if (!updateDrawer) {
    return null;
  }

  const getClassNames = (path: string) => {
    return classNames(styles.listItem, {
      [styles.listItemSelected]: pathname.includes(path),
      [styles.listItemShrinked]: !isDrawerOpen,
    });
  };

  const itemTextClassNames = classNames(styles.listItemText, {
    [styles.listItemTextHidden]: !isDrawerOpen,
  });

  return (
    <Drawer variant="permanent" anchor="left" open={isDrawerOpen}>
      <div style={{ minHeight: 70, padding: "0 10px" }}>
        <Image toggle={isDrawerOpen} />
      </div>
      <DrawerHeader>
        <div
          style={{ minHeight: 40, padding: "0 10px" }}
          onClick={updateDrawer}
        >
          <IconButton
            aria-label="toggle drawer"
            className={styles.toggleDrawerIcon}
          >
            {isDrawerOpen ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </IconButton>
        </div>
      </DrawerHeader>

      <List sx={{ mx: 2, flex: 1 }}>
        <ListItem disablePadding className={getClassNames("/report")}>
          <ListItemButton
            sx={{ minHeight: 40, px: 1.5 }}
            onClick={() => navigate("/report")}
          >
            <ListItemIcon className={styles.listItemIcon}>
              <SignalCellularAltIcon />
            </ListItemIcon>
            <ListItemText
              className={itemTextClassNames}
              primary="Report"
              disableTypography
            ></ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding className={getClassNames("/conversion-type")}>
          <ListItemButton
            sx={{ minHeight: 40, px: 1.5 }}
            onClick={() => navigate("/conversion-type")}
          >
            <ListItemIcon className={styles.listItemIcon}>
              <TableViewIcon />
            </ListItemIcon>
            <ListItemText
              className={itemTextClassNames}
              primary="Conversion type"
              disableTypography
            ></ListItemText>
          </ListItemButton>
        </ListItem>

        {isUserPermissionTo("create:user") && (
          <ListItem disablePadding className={getClassNames("/create-account")}>
            <ListItemButton
              sx={{ minHeight: 40, px: 1.5 }}
              onClick={() => navigate("/create-account")}
            >
              <ListItemIcon className={styles.listItemIcon}>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText
                className={itemTextClassNames}
                primary="Create account"
                disableTypography
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {isUserPermissionTo("create:job") && (
        <ListItem disablePadding className={getClassNames("/run-types")}>
          <ListItemButton
            sx={{ minHeight: 40, px: 1.5 }}
            onClick={() => navigate("/run-types")}
          >
            <ListItemIcon className={styles.listItemIcon}>
              <DirectionsRunIcon />
            </ListItemIcon>
            <ListItemText
              className={itemTextClassNames}
              primary="Run types"
              disableTypography
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        )}
      </List>

      <List sx={{ bottom: "0", mx: 2 }}>
        <Divider />

        <ListItem disablePadding className={getClassNames("logout")}>
          <ListItemButton sx={{ minHeight: 40, px: 1.5 }} onClick={logoutUser}>
            <ListItemIcon className={styles.listItemIcon}>
              <LogoutIcon className={styles.logoutButton} />
            </ListItemIcon>
            <ListItemText
              className={itemTextClassNames}
              primary="Logout"
              disableTypography
            ></ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideDrawer;
