// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button__AuthButton__yFmdo{justify-content:center;align-items:center;color:#0f113e;background:#ffc509;width:130px;height:50px;border-radius:6px;font-weight:600;font-size:1.4rem}", "",{"version":3,"sources":["webpack://./src/app/landing/components/AuthButton.scss","webpack://./src/styles/_settings.scss"],"names":[],"mappings":"AAEA,2BACE,sBAAA,CACA,kBAAA,CACA,aCDU,CDEV,kBCGO,CDFP,WAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":["@import \"src/styles/common\";\n\n.button {\n  justify-content: center;\n  align-items: center;\n  color: $dark-blue;\n  background: $yellow;\n  width: 130px;\n  height: 50px;\n  border-radius: 6px;\n  font-weight: 600;\n  font-size: 1.4rem;\n}\n","// Colour Palette\n// ---------\n\n// Main colors\n$dark-blue: #0f113e;\n$blue: #0c539b;\n$light-blue: #0995F8;\n$blue-gray: #6186a0;\n$green: #76f3d4;\n$yellow: #ffC509;\n\n$black: #101827;\n$white: #fff;\n$off-white: #f9fafc;\n\n$light-gray: #f1f2f4;\n$medium-light-gray: #d4d9de;\n$gray: #b7c0c8;\n$medium-dark-gray: #9aa7b1;\n$dark-gray: #6f8190;\n\n$red: #d90000;\n\n:export {\n  black: $black;\n  dark-blue: $dark-blue;\n  light-blue: $blue;\n  red: $red;\n  dark-grey: $dark-gray;\n  medium-dark-grey: $medium-dark-gray;\n  grey: $gray;\n  medium-light-grey: $medium-light-gray;\n  light-grey: $light-gray;\n  green: $green;\n  yellow: $yellow;\n  white: $white;\n}\n\n// font weights\n$light: 300;\n$normal: 400;\n$bold: 700;\n$dark: 900;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#101827",
	"dark-blue": "#0f113e",
	"light-blue": "#0c539b",
	"red": "#d90000",
	"dark-grey": "#6f8190",
	"medium-dark-grey": "#9aa7b1",
	"grey": "#b7c0c8",
	"medium-light-grey": "#d4d9de",
	"light-grey": "#f1f2f4",
	"green": "#76f3d4",
	"yellow": "#ffc509",
	"white": "#fff",
	"button": "button__AuthButton__yFmdo"
};
export default ___CSS_LOADER_EXPORT___;
