import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type CounterState = {
    isDrawerOpen: boolean
}

const initialState: CounterState = {
    isDrawerOpen: false
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        toggleDrawer: (state, action: PayloadAction<boolean>) => {
            state.isDrawerOpen = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggleDrawer } = clientSlice.actions

export default clientSlice.reducer
