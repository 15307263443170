import React from "react";
import { ICsvDownloadProps, toCsv } from "react-csv-downloader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as FileSaver from "file-saver";
import style from "./CsvButton.scss";

type CsvReportProps = {
  isLoading: boolean,
  columns: { id: string, displayName: string }[]
  datas: {
    account_id: string;
    account: string;
    campaign_id: string;
    campaign: string;
    ad_set_id: string;
    ad_set: string;
    conversion_type: string;
    conversions: string;
    conversions_value: string
    ad_spend: string;
    clicks: string;
    impressions: string;
    reach: string;
  }[] | undefined
};

const CsvReport = (props: CsvReportProps) => {
  const {
    isLoading,
    columns,
    datas,
  } = props;

  if (isLoading || datas === undefined) {
    return null;
  }

  let filename = "omni_report_" + (new Date().toLocaleDateString());
  return (
    <CsvReportInner
      filename={filename}
      extension=".csv"
      separator=","
      columns={columns}
      datas={datas}
      text="Download CSV Report"
      className={style.csvButton}
    />
  );
};

export default CsvReport;

class CsvReportInner extends React.Component<ICsvDownloadProps> {
  public handleClick = async () => {
    const {
      suffix,
      prefix,
      bom,
      extension,
      disabled,
      meta,
      separator,
    } = this.props;

    if (disabled) {
      return;
    }

    let { filename } = this.props;
    let csv: string | void;
    try {
      csv = await toCsv(this.props);
    } catch (err) {
      console.log(err);
    }
    if (!csv) {
      csv = "";
    }
    const bomCode = bom !== false ? "\ufeff" : "";
    const metaContent = meta ? `sep=${separator}\r\n` : "";

    const resolvedExtension = extension || ".csv";
    if (filename.indexOf(resolvedExtension) === -1) {
      filename += resolvedExtension;
    }

    if (suffix) {
      filename =
        typeof suffix === "string" || typeof suffix === "number"
          ? filename.replace(resolvedExtension, `_${suffix}${resolvedExtension}`)
          : filename.replace(resolvedExtension, `_${new Date().getTime()}${resolvedExtension}`);
    }

    if (prefix) {
      filename =
        typeof prefix === "string" || typeof prefix === "number"
          ? `${prefix}_${filename}`
          : `${new Date().getTime()}_${filename}`;
    }

    const blob = new Blob([`${bomCode}${metaContent}${csv}`], {
      type: "text/csv;charset=utf-8"
    });
    FileSaver.saveAs(blob, filename);
  };

  public render() {
    const {
      children,
      text,
      disabled,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      bom,
      filename,
      extension,
      prefix,
      suffix,
      meta,
      handleError,
      handleEmpty,
      columns,
      datas,
      separator,
      noHeader,
      wrapColumnChar,
      newLineAtEnd,
      chunkSize,
      ...props
    } = this.props;

    if (typeof children === "undefined") {
      return (
        <button type="button" {...props} onClick={this.handleClick} disabled={disabled}>
          <FileDownloadIcon />
        </button>
      );
    }

    return (
      <div role="button" tabIndex={0} {...props} onClick={this.handleClick}>
        <FileDownloadIcon />
      </div>
    );
  }
}