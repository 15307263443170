import {
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import React from "react";

export enum Level {
  ACCOUNT = "account",
  CAMPAIGN = "campaign",
  ADSET = "adset",
}

type LevelPickerProps = {
  onChange: (level: Level) => void;
  level: string;
};

const LevelPicker = (props: LevelPickerProps) => {
  const { onChange, level } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as Level);
  };

  return (
    <FormControl
      sx={{
        minWidth: 200,
        display: "flex",
        gap: "10px",
        margin: "10px 0 10px 10px",
      }}
    >
      <InputLabel id="level-select-label">Level</InputLabel>
      <Select
        labelId="level-select-label"
        id="level-select"
        value={level}
        label="Level"
        onChange={handleChange}
      >
        <MenuItem value={Level.ACCOUNT}>Account</MenuItem>
        <MenuItem value={Level.CAMPAIGN}>Campaign</MenuItem>
        <MenuItem value={Level.ADSET}>Ad Set</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LevelPicker;
