import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'
import { store } from './store'

import App from "./App";

import './styles/main';

const root = createRoot(
    document.getElementById("root") as HTMLDivElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
