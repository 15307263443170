import React, { useContext } from "react";
import classNames from "classnames";
import Datepicker from "src/shared/components/datepicker/Datepicker";
import MultipleSelect, { GroupedMultipleSelect } from "src/shared/components/multi-select/MultiSelect";
import { CustomConversionsContext } from "../../CustomConversions";

import styles from "./CustomConversionsFilters.scss";
import { filterAccounts, filterCampaigns } from "src/shared/helpers/Filters";

const CustomConversionsFilters = () => {
  const {
    reportData,
    selectedAccounts,
    selectableAccounts,
    setSelectedAccounts,
    setSelectableAccounts,
    selectedCampaigns,
    selectableCampaigns,
    setSelectedCampaigns,
    setSelectableCampaigns,
    setSelectedAdsets,
    setSelectableAdsets,
    selectedCustomConversions,
    selectableCustomConversions,
    setSelectedCustomConversions,
    setSelectableCustomConversions,
    dateRange,
    setDateRange
  } = useContext(CustomConversionsContext);

  if (
    !(
      reportData &&
      selectedAccounts &&
      selectableAccounts &&
      setSelectedAccounts &&
      setSelectableAccounts &&
      selectedCampaigns &&
      selectableCampaigns &&
      setSelectedCampaigns &&
      setSelectableCampaigns &&
      setSelectedAdsets &&
      setSelectableAdsets &&
      selectedCustomConversions &&
      selectableCustomConversions &&
      setSelectedCustomConversions &&
      setSelectableCustomConversions &&
      dateRange &&
      setDateRange
    )
  ) {
    return null;
  }

  const onAccountsChange = (values: string[]) => {
    filterAccounts(
      values,
      setSelectedAccounts,
      setSelectedCampaigns,
      setSelectableCampaigns,
      setSelectedAdsets,
      setSelectableAdsets,
      setSelectedCustomConversions,
      setSelectableCustomConversions,
      reportData
    );
  };

  const onCampaignsChange = (values: string[]) => {
    filterCampaigns(
      values,
      setSelectedCampaigns,
      setSelectedAdsets,
      setSelectableAdsets,
      setSelectedCustomConversions,
      setSelectableCustomConversions,
      reportData
    );
  };

  const filtersClasses = classNames(styles.selectors, styles.selectorsOpen);
  return (
    <div className={styles.reportFilters}>
      <div style={{ display: "flex" }}>
        <div className={styles.datePicker}>
          <Datepicker dateRange={dateRange} onChange={setDateRange} />
        </div>
        <div className={filtersClasses}>
          <MultipleSelect
            label={"Account"}
            selectedValues={selectedAccounts}
            options={selectableAccounts}
            onChange={onAccountsChange}
          />
          <GroupedMultipleSelect
            label={"Campaign"}
            selectedValues={selectedCampaigns}
            options={selectableCampaigns}
            onChange={onCampaignsChange}
          />
          <GroupedMultipleSelect
            label={"Conversion Types"}
            selectedValues={selectedCustomConversions}
            options={selectableCustomConversions}
            onChange={setSelectedCustomConversions}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomConversionsFilters;
