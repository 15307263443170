import fetch from "cross-fetch";

import config from "config";
import authService from "./auth-service";

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type APIError = {
  status: number;
  message: string;
};

type ApiServiceConfig = {
  host: string;
};

export type FetchOptions = {
  host?: string;
  method?: HTTPMethod;
  headers?: { [key: string]: string };
  body?: string; // stringified json
  preserveEndpoint?: boolean;
  signal?: AbortSignal;
};

const defaultMethod = HTTPMethod.GET;
const defaultHeaders = {
  Accept: "application/json",
};

class ApiService {
  private host: string;

  public constructor(config: ApiServiceConfig) {
    this.host = config.host || "";
  }

  public getFetch() {
    return fetch;
  }

  private buildFetchOptions(options: FetchOptions) {
    const headers: { [key: string]: string } = {
      ...defaultHeaders,
      ...options.headers,
    };

    if (!options.preserveEndpoint) {
      headers["x-access-token"] = authService.getUser()?.readToken;
    }

    if (
      options.method &&
      [HTTPMethod.POST, HTTPMethod.PUT, HTTPMethod.PATCH].includes(
        options.method
      ) &&
      !headers.hasOwnProperty("Content-Type")
    ) {
      headers["Content-Type"] = "application/json";
    }

    return {
      method: options.method || defaultMethod,
      headers: headers,
      body: options.body,
      signal: options.signal,
    };
  }

  public async fetch(endpoint: string, options: FetchOptions = {}) {
    const host = options.host || this.host;
    const fetch = this.getFetch();
    const url = options.preserveEndpoint ? endpoint : `${host}${endpoint}`;

    const fetchOptions = this.buildFetchOptions(options);

    try {
      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        throw await this.handleError(response);
      }
      return await this.handleResponse(response, fetchOptions);
    } catch (error) {
      if ((error as any).name !== "AbortError") {
        throw error;
      }
    }
  }

  private async handleError(response: Response) {
    let errorJson;
    try {
      errorJson = await response.json();
    } finally {
      return {
        status: response.status,
        ...errorJson,
      };
    }
  }

  private async handleResponse(response: Response, options: FetchOptions) {
    if (options.headers && options.headers["Accept"] === "application/json") {
      return await response.json();
    } else {
      return await response.text();
    }
  }
}

export default new ApiService({
  host: config.reactAppBackendUrl as string,
});
