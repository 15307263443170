import React from "react";
import { MuiChipsInput } from "mui-chips-input";

export const MultiInput = ({ title, isError, addValues, fieldName }) => {
  const [chips, setChips] = React.useState([]);

  const handleChange = (newChips) => {
    addValues(newChips, fieldName);
    setChips(newChips);
  };

  return <MuiChipsInput error={isError} label={title} value={chips} onChange={handleChange} />;
};
