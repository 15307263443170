import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Auth0Provider } from "@auth0/auth0-react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Landing from "src/app/landing/Landing";
import Report from "./app/client/report/Report";

import NotFound from "./app/not-found/NotFound";
import { CreateAccount } from "./app/client/create-account/CreateAccount";
import CustomConversions from "./app/client/custom-coversions/CustomConversions";
import styles from "./App.scss";
import LsToken from "src/app/ls-token/LsToken";

import { RunTypes } from "./app/client/run-types/RunTypes";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#0f123f",
    },
  },
});

const App = () => {
  return (
    <div className={styles.app}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Auth0Provider
          domain="auth.omnilightning.com"
          clientId={process.env.REACT_APP_CLIENT_ID || ""}
          redirectUri={window.location.origin}
          audience="https://dev-4xc264o6.us.auth0.com/api/v2/"
          scope="read:current_user update:current_user_metadata"
        >
          <BrowserRouter>
            <Routes>
              <Route index element={<Landing />} />
              <Route path="/report" element={<Report />} />

              <Route path="/create-account" element={<CreateAccount />} />

              <Route path="/run-types" element={<RunTypes />} />

              <Route path="/conversion-type" element={<CustomConversions />} />

              <Route path="/ls-token" element={<LsToken />} />

              <Route path="/not_found" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </Auth0Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
