import React from "react";
import useAuthService from "src/shared/hooks/useAuthService";

import styles from "./AuthButton.scss";

const LoginButton = () => {
  const { loginUser } = useAuthService();

  return (
    <button className={styles.button} onClick={loginUser}>
      Login
    </button>
  );
};

export default LoginButton;
