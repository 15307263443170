import React from "react";

import KodyAvatar from "static/images/landing/avatars/Kody.png";
import ScottAvatar from "static/images/landing/avatars/Scott.png";

import styles from "./../Landing.scss";
import classNames from "classnames";

const OurTeam = () => {
  return (
    <div
      className={classNames(styles.paddedSection, styles.paddedSectionDarkBlue)}
    >
      <div className={styles.ourTeam}>
        <div className={styles.top}>
          <div
            className={classNames(
              styles.sectionHeader,
              styles.sectionHeaderCenter,
              styles.sectionHeaderSmallPadding,
              styles.sectionHeaderLightBlue
            )}
          >
            Meet our team
          </div>
          <div className={styles.info}>
            Omni Lightning was developed by three founders with a mission to
            help retailers leverage their data to grow and increase sales
          </div>
        </div>
        <div className={styles.members}>
          <div className={styles.member}>
            <div className={styles.avatar}>
              <img alt="Kody Fitzjerrells" src={KodyAvatar} />
            </div>
            <div className={styles.name}>Kody Fitzjerrells</div>
            <div className={styles.role}>President and Founder</div>
          </div>

          <div className={styles.member}>
            <div className={styles.avatar}>
              <img alt="Scott Schafer" src={ScottAvatar} />
            </div>
            <div className={styles.name}>Scott Schafer</div>
            <div className={styles.role}>
              Lead Developer and Project Manager
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
