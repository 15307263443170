import React, { useContext, useEffect, useState } from "react";

import {
  AutoDateRange,
  DateRange,
  getDateRange
} from "src/shared/components/datepicker/Datepicker";
import apiService, { HTTPMethod } from "src/services/api-service";
import ReportFilters from "./components/report-filters/ReportFilters";

import useAuthService from "src/shared/hooks/useAuthService";
import Stats from "./components/stats/Stats";
import StandardLayout from "src/shared/components/standard-layout/StandardLayout";
import {
  ReportContextType,
  ReportData,
  defaultReportcontext
} from "../state/reportTypes";
import config from "config";
import Loader from "./components/loader/Loader";
import { useSelector } from "react-redux";
import { getDrawerOpenStatus } from "../state/clientSelector";
import { Level } from "./components/levelpicker/LevelPicker";

import style from "./Report.scss";
import { filterAccounts, filterCampaigns } from "src/shared/helpers/Filters";
import CsvReport from "src/shared/components/CsvReport";

export const ReportContext =
  React.createContext<ReportContextType>(defaultReportcontext);

const ReportWrapper = () => {
  const { currentUser, redirectIfNotLoggedIn, isAuthenticating } =
    useAuthService();

  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectableAccounts, setSelectableAccounts] = useState<
    { key: string; value: string }[]
  >([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectableCampaigns, setSelectableCampaigns] = useState<
    { group: string, inner: { key: string; value: string }[] }[]
  >([]);
  const [selectedAdsets, setSelectedAdsets] = useState<string[]>([]);
  const [selectableAdsets, setSelectableAdsets] = useState<
    { group: string, inner: { key: string; value: string }[] }[]
  >([]);
  const [selectedCustomConversions, setSelectedCustomConversions] = useState<string[]>([]);
  const [selectableCustomConversions, setSelectableCustomConversions] = useState<
    { group: string, inner: { key: string; value: string }[] }[]
  >([]);
  const [reportData, setReportData] = React.useState<ReportData | null>(null);
  const [previousData, setPreviousData] = React.useState<ReportData | null>(
    null
  );
  const [dateRange, setDateRange] = useState<DateRange>({
    ...getDateRange(AutoDateRange.LAST_28_DAYS),
    range: AutoDateRange.LAST_28_DAYS
  });
  const [level, setLevel] = useState<string>(Level.ACCOUNT);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (
      dateRange !== null &&
      !isAuthenticating &&
      currentUser.readToken &&
      level !== null
    ) {
      setIsLoading(true);
      setReportData(null);
      apiService
        .fetch(`${config.reactAppBackendUrl}/report`, {
          preserveEndpoint: true,
          headers: {
            Authorization: `Bearer ${currentUser?.readToken}`
          },
          body: JSON.stringify({
            lvl: level,
            start: dateRange?.startDate?.toISOString().substring(0, 10),
            end: dateRange?.endDate?.toISOString().substring(0, 10)
          }),
          method: HTTPMethod.POST
        })
        .then((data) => {
          let reportData = new ReportData(data["data"]);
          setReportData(reportData);
          if (selectedAccounts.length == 0) {
            setSelectedAccounts(reportData.accounts.map((item) => item.key));
            setSelectableAccounts(reportData.accounts);
          }
          if (level == Level.CAMPAIGN && selectedCampaigns.length == 0) {
            filterAccounts(
              selectedAccounts,
              setSelectedAccounts,
              setSelectedCampaigns,
              setSelectableCampaigns,
              setSelectedAdsets,
              setSelectableAdsets,
              setSelectedCustomConversions,
              setSelectableCustomConversions,
              reportData
            );
          }
          if (level == Level.ADSET && selectedAdsets.length == 0) {
            if (selectedCampaigns.length == 0) {
              filterAccounts(
                selectedAccounts,
                setSelectedAccounts,
                setSelectedCampaigns,
                setSelectableCampaigns,
                setSelectedAdsets,
                setSelectableAdsets,
                setSelectedCustomConversions,
                setSelectableCustomConversions,
                reportData
              );
            } else {
              filterCampaigns(
                selectedCampaigns,
                setSelectedCampaigns,
                setSelectedAdsets,
                setSelectableAdsets,
                setSelectedCustomConversions,
                setSelectableCustomConversions,
                reportData
              );
            }
          }
        });

      apiService
        .fetch(`${config.reactAppBackendUrl}/report`, {
          preserveEndpoint: true,
          headers: {
            Authorization: `Bearer ${currentUser?.readToken}`
          },
          body: JSON.stringify({
            lvl: level,
            start: dateRange?.previousStartDate?.toISOString().substring(0, 10),
            end: dateRange?.previousEndDate?.toISOString().substring(0, 10)
          }),
          method: HTTPMethod.POST
        })
        .then((data) => {
          let reportData = new ReportData(data["data"]);
          setPreviousData(reportData);
          setIsLoading(false);
        });
    }
  }, [
    dateRange?.previousEndDate?.toISOString().substring(0, 10),
    dateRange?.endDate?.toISOString().substring(0, 10),
    level,
    isAuthenticating,
    currentUser.readToken
  ]);

  useEffect(() => {
    if (!isAuthenticating) {
      redirectIfNotLoggedIn();
    }
  }, [isAuthenticating]);

  return (
    <ReportContext.Provider
      value={{
        reportData,
        previousData,
        selectedAccounts,
        selectableAccounts,
        setSelectedAccounts,
        setSelectableAccounts,
        selectedCampaigns,
        selectableCampaigns,
        setSelectedCampaigns,
        setSelectableCampaigns,
        selectedAdsets,
        selectableAdsets,
        setSelectedAdsets,
        setSelectableAdsets,
        selectedCustomConversions,
        selectableCustomConversions,
        setSelectedCustomConversions,
        setSelectableCustomConversions,
        dateRange,
        setDateRange,
        level,
        setLevel,
        isLoading,
        setIsLoading
      }}
    >
      <Report />
    </ReportContext.Provider>
  );
};


const Report = () => {
  const {
    reportData,
    previousData,
    isLoading,
    selectedAccounts,
    selectedCampaigns,
    selectedAdsets,
    level
  } = useContext(ReportContext);

  const isDrawerOpen = useSelector(getDrawerOpenStatus);

  let all_columns = [
    {
      id: "account_id",
      displayName: "Account Id"
    }, {
      id: "account",
      displayName: "Account"
    }, {
      id: "campaign_id",
      displayName: "Campaign Id"
    }, {
      id: "campaign",
      displayName: "Campaign"
    }, {
      id: "ad_set_id",
      displayName: "Ad Set Id"
    }, {
      id: "ad_set",
      displayName: "Ad Set"
    }, {
      id: "conversions",
      displayName: "Conversions"
    }, {
      id: "conversions_value",
      displayName: "Conversion Value"
    }, {
      id: "ad_spend",
      displayName: "Ad Spend"
    }, {
      id: "clicks",
      displayName: "Clicks"
    }, {
      id: "impressions",
      displayName: "Impressions"
    }, {
      id: "reach",
      displayName: "Reach"
    }
  ];

  // Only include campain* and ad_set* if needed
  let columns = all_columns.filter((column) => {
    if (column.id.includes("campaign")) {
      return level != "account";
    } else if (column.id.includes("ad_set")) {
      return level === "adset";
    } else {
      return true;
    }
  });

  const datas = reportData?.insights.filter((insight) => {
    if (level === "account") {
      return selectedAccounts.includes(insight.account_id);
    } else if (level == "campaign") {
      return selectedCampaigns.includes(insight.campaign_id);
    } else {
      return selectedAdsets.includes(insight.ad_set_id);
    }
  }).map((data) => {
    return {
      account_id: data.account_id,
      account: data.account,
      campaign: data.campaign,
      campaign_id: data.campaign_id,
      ad_set: data.ad_set,
      ad_set_id: data.ad_set_id,
      conversion_type: data.custom_conversion,
      ad_spend: data.ad_spend.toString(),
      clicks: data.clicks.toString(),
      conversions: data.conversions.toString(),
      conversions_value: data.conversions_value.toString(),
      impressions: data.impressions.toString(),
      reach: data.reach.toString()
    };
  });

  const mainContent =
    reportData !== null && !isLoading && previousData !== null ? (
      <div className={style.report}>
        <ReportFilters />
        <Stats />
        <CsvReport
          isLoading={isLoading}
          columns={columns}
          datas={datas}
        />
      </div>
    ) : (
      <Loader />
    );

  return (
    <StandardLayout mainContent={mainContent} isDrawerOpen={isDrawerOpen} />
  );
};

export default ReportWrapper;
