import React, { useEffect, useState } from "react";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Select, MenuItem, SelectChangeEvent, Box, InputLabel, FormControl } from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export enum AutoDateRange {
    CUSTOM = 'custom',
    LAST_7_DAYS = 'last_7_days',
    LAST_14_DAYS = 'last_14_days',
    LAST_28_DAYS = 'last_28_days',
    LAST_30_DAYS = 'last_30_days',
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    WEEK = 'this_week',
    LAST_WEEK = 'last_week',
    MONTH = 'this_month',
    LAST_MONTH = 'last_month',
    YEAR = 'this_year',
    LAST_YEAR = 'last_year',
    QUARTER = 'this_quarter',
    LAST_QUARTER = 'last_quarter'
}

export type DateRange = {
    startDate: Date | null,
    endDate: Date | null,
    previousStartDate: Date | null,
    previousEndDate: Date | null,
    range: AutoDateRange
};

type DatepickerProps = {
    onChange: (dateRange: DateRange) => void,
    dateRange: DateRange
}
const Datepicker = (props: DatepickerProps) => {

    const { onChange, dateRange } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                margin: "10px 0 10px 10px"
            }}>
                <RangeSelector dateRange={dateRange} onChange={onChange} />

                {dateRange.range === AutoDateRange.CUSTOM &&
                    <>
                        <DatePicker
                            label="Start date"
                            value={dateRange.startDate}
                            maxDate={moment().toDate()}
                            minDate={moment().subtract(36, 'months').toDate()}
                            onChange={(newValue) => {
                                onChange({
                                    ...dateRange,
                                    startDate: newValue,
                                    range: AutoDateRange.CUSTOM
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />

                        <DatePicker
                            label="End date"
                            value={dateRange.endDate}
                            maxDate={moment().toDate()}
                            minDate={moment().subtract(36, 'months').toDate()}
                            onChange={(newValue) => {
                                onChange({
                                    ...dateRange,
                                    endDate: newValue,
                                    range: AutoDateRange.CUSTOM
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </>
                }
            </Box>
        </LocalizationProvider>
    );
}

type RangeSelectorProps = {
    dateRange: DateRange,
    onChange: (dateRange: DateRange) => void
}
const RangeSelector = (props: RangeSelectorProps) => {

    const { dateRange, onChange } = props;

    const handleChange = (event: SelectChangeEvent) => {
        onChange({
            ...getDateRange(event.target.value as AutoDateRange),
            range: event.target.value as AutoDateRange
        });
    };

    return <>
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="range-select">Range</InputLabel>

            <Select
                labelId={"range-select"}
                value={dateRange.range}
                label="Range"
                onChange={handleChange}
            >
                <MenuItem value={'custom'}>Custom</MenuItem>
                <MenuItem value={'last_7_days'}>Last 7 Days</MenuItem>
                <MenuItem value={'last_14_days'}>Last 14 Days</MenuItem>
                <MenuItem value={'last_28_days'}>Last 28 Days</MenuItem>
                <MenuItem value={'last_30_days'}>Last 30 Days</MenuItem>
                <MenuItem value={'today'}>Today</MenuItem>
                <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                <MenuItem value={'this_week'}>This Week</MenuItem>
                <MenuItem value={'last_week'}>Last Week</MenuItem>
                <MenuItem value={'this_month'}>This Month</MenuItem>
                <MenuItem value={'last_month'}>Last Month</MenuItem>
                <MenuItem value={'this_year'}>This Year</MenuItem>
                <MenuItem value={'last_year'}>Last Year</MenuItem>
                <MenuItem value={'this_quarter'}>This Quarter</MenuItem>
                <MenuItem value={'last_quarter'}>Last Quarter</MenuItem>
            </Select>
        </FormControl>
    </>
}


export const getRangeText = (range: AutoDateRange) => {
    switch (range) {
        case AutoDateRange.LAST_7_DAYS:
            return "7 days";
        case AutoDateRange.LAST_14_DAYS:
            return "14 days";
        case AutoDateRange.LAST_28_DAYS:
            return "28 days";
        case AutoDateRange.LAST_30_DAYS:
            return "30 days";
        case AutoDateRange.TODAY:
            return "day";
        case AutoDateRange.YESTERDAY:
            return "day";
        case AutoDateRange.WEEK:
            return "week";
        case AutoDateRange.LAST_WEEK:
            return "week";
        case AutoDateRange.MONTH:
            return "month";
        case AutoDateRange.LAST_MONTH:
            return "month";
        case AutoDateRange.YEAR:
            return "year";
        case AutoDateRange.LAST_YEAR:
            return "year";
        case AutoDateRange.QUARTER:
            return "quarter";
        case AutoDateRange.LAST_QUARTER:
            return "quarter";
        default:
            return "day";

    }
}

export const getDateRange = (range: AutoDateRange) => {

    const currentDate = moment().toDate();

    switch (range) {
        case AutoDateRange.LAST_7_DAYS:
            return {
                startDate: moment().subtract(7, 'd').toDate(),
                endDate: currentDate,
                previousStartDate: moment().subtract(14, 'd').toDate(),
                previousEndDate: moment().subtract(7, 'd').toDate()
            };
        case AutoDateRange.LAST_14_DAYS:
            return {
                startDate: moment().subtract(14, 'd').toDate(),
                endDate: currentDate,
                previousStartDate: moment().subtract(28, 'd').toDate(),
                previousEndDate: moment().subtract(14, 'd').toDate()
            }
        case AutoDateRange.LAST_28_DAYS:
            return {
                startDate: moment().subtract(28, 'd').toDate(),
                endDate: currentDate,
                previousStartDate: moment().subtract(56, 'd').toDate(),
                previousEndDate: moment().subtract(28, 'd').toDate()
            }
        case AutoDateRange.LAST_30_DAYS:
            return {
                startDate: moment().subtract(30, 'd').toDate(),
                endDate: currentDate,
                previousStartDate: moment().subtract(60, 'd').toDate(),
                previousEndDate: moment().subtract(30, 'd').toDate()
            }
        case AutoDateRange.TODAY:
            return {
                startDate: moment().startOf("day").toDate(),
                endDate: currentDate,
                previousStartDate: moment().subtract(1, 'd').startOf("day").toDate(),
                previousEndDate: moment().subtract(1, 'd').endOf("day").toDate()
            }
        case AutoDateRange.YESTERDAY:
            return {
                startDate: moment().subtract(1, 'd').startOf("day").toDate(),
                endDate: moment().subtract(1, 'd').endOf("day").toDate(),
                previousStartDate: moment().subtract(2, 'd').startOf("day").toDate(),
                previousEndDate: moment().subtract(2, 'd').endOf("day").toDate()
            }
        case AutoDateRange.WEEK:
            return {
                startDate: moment().startOf("week").toDate(),
                endDate: moment().endOf("week").toDate(),
                previousStartDate: moment().subtract(1, 'w').startOf("week").toDate(),
                previousEndDate: moment().subtract(1, 'w').endOf("week").toDate()
            }
        case AutoDateRange.LAST_WEEK:
            return {
                startDate: moment().subtract(1, 'w').startOf("week").toDate(),
                endDate: moment().subtract(1, 'w').endOf("week").toDate(),
                previousStartDate: moment().subtract(2, 'w').startOf("week").toDate(),
                previousEndDate: moment().subtract(2, 'w').endOf("week").toDate()
            }
        case AutoDateRange.MONTH:
            return {
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("month").toDate(),
                previousStartDate: moment().subtract(1, 'M').startOf("month").toDate(),
                previousEndDate: moment().subtract(1, 'M').endOf("month").toDate()
            }
        case AutoDateRange.LAST_MONTH:
            return {
                startDate: moment().subtract(1, 'M').startOf("month").toDate(),
                endDate: moment().subtract(1, 'M').endOf("month").toDate(),
                previousStartDate: moment().subtract(2, 'M').startOf("month").toDate(),
                previousEndDate: moment().subtract(2, 'M').endOf("month").toDate()
            }
        case AutoDateRange.YEAR:
            return {
                startDate: moment().startOf("year").toDate(),
                endDate: moment().endOf("year").toDate(),
                previousStartDate: moment().subtract(1, 'y').startOf("year").toDate(),
                previousEndDate: moment().subtract(1, 'y').endOf("year").toDate()
            }
        case AutoDateRange.LAST_YEAR:
            return {
                startDate: moment().subtract(1, 'y').startOf("year").toDate(),
                endDate: moment().subtract(1, 'y').endOf("year").toDate(),
                previousStartDate: moment().subtract(2, 'y').startOf("year").toDate(),
                previousEndDate: moment().subtract(2, 'y').endOf("year").toDate()
            }
        case AutoDateRange.QUARTER:
            return {
                startDate: moment().startOf("quarter").toDate(),
                endDate: moment().endOf("quarter").toDate(),
                previousStartDate: moment().subtract(1, "Q").startOf("quarter").toDate(),
                previousEndDate: moment().subtract(1, 'Q').endOf("quarter").toDate()
            }
        case AutoDateRange.LAST_QUARTER:
            return {
                startDate: moment().subtract(1, "Q").startOf("quarter").toDate(),
                endDate: moment().subtract(1, 'Q').endOf("quarter").toDate(),
                previousStartDate: moment().subtract(2, "Q").startOf("quarter").toDate(),
                previousEndDate: moment().subtract(2, 'Q').endOf("quarter").toDate()
            }
        default:
            return {
                startDate: moment().subtract(28, 'd').toDate(),
                endDate: currentDate,
                previousStartDate: moment().subtract(56, 'd').toDate(),
                previousEndDate: moment().subtract(28, 'd').toDate()
            }

    }

}
export default Datepicker;
