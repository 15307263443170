import React from "react";

import { Insight } from "../../../state/reportTypes";
import {
  currencyFormatter,
  numberFormatter,
} from "src/shared/helpers/formatters";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import styles from "./Stats.scss";

const availableStats = [
  "ad_spend",
  "conversions_value",
  "conversions",
  "impressions",
  "clicks",
  "reach",
];

const processFilter = (params: {
  rawReportData: Insight[],
  prevRawReportData: Insight[],
  level: string
}) => {
  const {
    rawReportData,
    prevRawReportData,
    level
  } = params;

  let field_level = level === "adset" ? "ad_set" : level;

  const currentStats = {
    roas: 0,
    ad_spend: 0,
    conversions_value: 0,
    conversions: 0,
    impressions: 0,
    clicks: 0,
    reach: 0,
    cost_per_conversion: 0,
    avg_conversion_value: 0,
    conversion_data_sorted: [],
    ad_spend_data_sorted: [],
  };

  const previousStats = {
    roas: 0,
    ad_spend: 0,
    conversions_value: 0,
    conversions: 0,
    impressions: 0,
    clicks: 0,
    reach: 0,
    cost_per_conversion: 0,
    avg_conversion_value: 0,
  };

  const itemAdSpends: {
    [key: string]: {
      name: string;
      value: number;
    };
  } = {};

  const itemConversions: {
    [key: string]: {
      name: string;
      value: number;
    };
  } = {};

  rawReportData.forEach((data) => {
    if (itemAdSpends[data[field_level]]) {
      itemAdSpends[data[field_level]].value += Number(data.ad_spend.toFixed(2));
    } else {
      itemAdSpends[data[field_level]] = {
        name: data[field_level],
        value: Number(data.ad_spend.toFixed(2)),
      };
    }

    if (itemConversions[data[field_level]]) {
      itemConversions[data[field_level]].value += Number(
        data.conversions_value.toFixed(2)
      );
    } else {
      itemConversions[data[field_level]] = {
        name: data[field_level],
        value: Number(data.conversions_value.toFixed(2)),
      };
    }
  });

  const conversionDataSorted = Object.values(itemConversions).sort((a, b) =>
    a.value < b.value ? 1 : -1
  );

  const adSpendDataSorted = Object.values(itemAdSpends).sort((a, b) =>
    a.value < b.value ? 1 : -1
  );

  rawReportData.forEach((data) => {
    availableStats.forEach((stat) => {
      if (data[stat] && data[stat] !== null) {
        currentStats[stat] = currentStats[stat] + data[stat];
      }
    });

    currentStats.roas = currentStats.conversions_value / currentStats.ad_spend;
    currentStats.cost_per_conversion =
      currentStats.ad_spend / currentStats.conversions;
    currentStats.avg_conversion_value =
      currentStats.conversions_value / currentStats.conversions;
  });

  prevRawReportData.forEach((data) => {
    availableStats.forEach((stat) => {
      if (data[stat] && data[stat] !== null) {
        previousStats[stat] = previousStats[stat] + data[stat];
      }
    });

    previousStats.roas =
      previousStats.conversions_value / previousStats.ad_spend;
    previousStats.cost_per_conversion =
      previousStats.ad_spend / previousStats.conversions;
    previousStats.avg_conversion_value =
      previousStats.conversions_value / previousStats.conversions;
  });

  const current = {
    ad_spend: currencyFormatter.format(currentStats.ad_spend),
    conversions_value: currencyFormatter.format(currentStats.conversions_value),
    cost_per_conversion: currencyFormatter.format(
      currentStats.cost_per_conversion
    ),
    impressions: numberFormatter.format(currentStats.impressions),
    roas: numberFormatter.format(currentStats.roas),
    clicks: numberFormatter.format(currentStats.clicks),
    reach: numberFormatter.format(currentStats.reach),
    conversions: numberFormatter.format(currentStats.conversions),
    avg_conversion_value: currencyFormatter.format(
      currentStats.avg_conversion_value
    ),
    conversion_data_sorted: conversionDataSorted,
    ad_spend_data_sorted: adSpendDataSorted,
  };

  const previous = {
    ad_spend: statsFormatter(
      currentStats.ad_spend,
      previousStats.ad_spend,
      "currency"
    ),
    conversions_value: statsFormatter(
      currentStats.conversions_value,
      previousStats.conversions_value,
      "currency"
    ),
    cost_per_conversion: statsFormatter(
      currentStats.cost_per_conversion,
      previousStats.cost_per_conversion,
      "currency"
    ),
    impressions: statsFormatter(
      currentStats.impressions,
      previousStats.impressions
    ),
    roas: statsFormatter(currentStats.roas, previousStats.roas),
    clicks: statsFormatter(currentStats.clicks, previousStats.clicks),
    reach: statsFormatter(currentStats.reach, previousStats.reach),
    conversions: statsFormatter(
      currentStats.conversions,
      previousStats.conversions
    ),
    avg_conversion_value: statsFormatter(
      currentStats.avg_conversion_value,
      previousStats.avg_conversion_value,
      "currency"
    ),
  };

  return {
    current,
    previous,
  };
}

const statsFormatter = (
  current: number,
  previous: number,
  type?: "percentage" | "number" | "currency"
) => {
  const isPositive = current - previous >= 0;

  const arrowIcon = isPositive ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;

  if (type === "percentage") {
    return (
      <>
        <span className={isPositive ? styles.positive : styles.negative}>
          {arrowIcon}
          {percentageDifference(current, previous)}
        </span>
      </>
    );
  } else if (type === "currency") {
    return (
      <>
        <span className={isPositive ? styles.positive : styles.negative}>
          {arrowIcon}
          {currencyFormatter.format(Math.abs(current - previous))}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className={isPositive ? styles.positive : styles.negative}>
          {arrowIcon}
          {numberFormatter.format(Math.abs(current - previous))}
        </span>
      </>
    );
  }
};

const percentageDifference = (current, previous) => {
  const value = (((current - previous) * 100) / previous).toFixed(2);

  if (isNaN(Number(value))) {
    return "0%";
  }

  return ((Math.abs(current - previous) * 100) / previous).toFixed(2) + "%";
};

export { processFilter };
