import React from 'react';

import ExpandMore from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/Remove';

import PropTypes from 'prop-types';

import styles from './../Landing.scss';



const items = [
  {
    primary: 'How easy is it to implement Omni Lightning™ for my business?',
    secondary: 'Super simple! All we have to do is simple connecting work on our end and then your POS system will be connected and ready to leverage data to grow!',
  },
  {
    primary: 'How does Omni Lightning™ integrate to my POS?',
    secondary: 'Each POS system has what is called an API key. Through this key, we are able to connect Omni Lightning™ to your POS system and therefore connect it to Facebook. Omni Lightning™ acts almost like a bridge to make this happen.',
  },
  {
    primary: 'How does Facebook track the ROI?',
    secondary: 'Facebook is constantly tracking the engagement with your ads on Facebook i.e. – comments, clicks, shares, views, likes, etc. Then, when someone comes into your store and makes a purchase, Omni Lightning™ then matches up this engagement/action with the purchase data and like magic, counts it as a purchase inside Facebook’s platform.',
  },
  {
    primary: 'What does Facebook count as purchase?',
    secondary: 'When someone views/clicks on an ad (like, comment, share, click) and then comes into your store within 28 days and makes a purchase, that is the moment Facebook counts it as a purchase.',
  },
  {
    primary: 'How do you target based on brands?',
    secondary: 'Omni Lightning has the ability to pull specific brands you sell based on the name of that brand in your POS system. For example, we work with a lot of shoe retailers inside our marketing agency, Omni Digital Group. If they want to target everyone that has purchased ASICS or Nike in the past 365 days we can make this a specific audience inside Facebook and then create ads to remarket and drive them back in-store or online.',
  },
  {
    primary: 'How are you able to retarget my existing customers?',
    secondary: 'We take the data from the POS system and we do an upload into Facebook. We continue to do this every 30 days on-going to update so we can continually get in front of your existing customers with ads and increase the lifetime value of your customers.',
  },
  {
    primary: 'What data points does Facebook use to match people?',
    secondary: 'Facebook has to have customer data to match to someone’s unique profile to advertise to them. They do this through two major data points and then sub-data points as well. Email or phone numbers are the big two, and then things like name, city, state, and zip code help Facebook match as well.',
  },
];


const FaqItems = () => {
  return (
    <div className={styles.paddedSection}>
      <div className={styles.sectionHeader}>FAQ</div>
      <div className={styles.faqItems}>
        {items.map((item) => (
          <FaqItem
            key={item.primary}
            primary={item.primary}
            secondary={item.secondary}
          />
        ))}
      </div>
    </div>
  );
};

FaqItems.propTypes = {
  items: PropTypes.array
}

const FaqItem = ({ primary, secondary }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.faqItem}>
      <div onClick={handleClick} className={styles.title}>
        <div>{primary}</div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      {open && <div className={styles.description}>
        {secondary}
      </div>}
    </div >
  );
};

FaqItem.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string
}

export default FaqItems;
