import Skeleton from "@mui/material/Skeleton";
import React from "react";

import styles from "../stats/Stats.scss";
import report_style from "src/app/client/report/Report.scss";

const LoadingStats = () => {
  return (
    <div className={report_style.report}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          width: "100%",
          height: "96px",
          borderRadius: "16px",
          padding: "10px",
          marginBottom: "20px",
        }}
      ></Skeleton>
      <div className={styles.stats}>
        <div className={styles.dashboard}>
          <FakeCard />
          <FakeCard />
          <FakeChart />
          <FakeCard />
          <FakeCard />
          <FakeChart />
          <FakeCard />
          <FakeCard />
          <div className={styles.vanity}>
            <FakeCard />
            <FakeCard />
            <FakeCard />
          </div>
        </div>
      </div>
    </div>
  );
};

const FakeCard = () => {
  return (
    <Skeleton
      className={styles.card}
      variant="rectangular"
      animation="wave"
      sx={{ height: "233px" }}
    />
  );
};

const FakeChart = () => {
  return (
    <Skeleton
      className={styles.smile}
      animation="wave"
      variant="rectangular"
      sx={{ height: "357.5px" }}
    />
  );
};
export default LoadingStats;
