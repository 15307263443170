import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AuthenticationButton from "src/app/landing/components/AuthButton";
import Logo from "src/shared/components/logo/Logo";

import styles from "./Header.scss";

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className={styles.appbar}
        sx={{ backgroundColor: "#0f113e", boxShadow: "none" }}
      >
        <Toolbar className={styles.toolbar}>
          <div
            style={{
              display: "flex",
              flex: 1,
              height: "75px",
            }}
          >
            <Logo />
          </div>
          <div style={{ flex: 1, height: "75px" }} />
          <div className={styles.actionButton}>
            <AuthenticationButton />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
