import React from "react";
import { useNavigate } from "react-router-dom";
import OlLogo from "static/images/logo/ol-logo.svg";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <span
      onClick={() => navigate("/")}
      style={{ cursor: "pointer", display: "inline-flex", height: "100%" }}
    >
      <OlLogo height="100%" style={{ justifySelf: "center" }} />
    </span>
  );
};

Logo.defaultProps = {
  theme: "dark",
};

export default Logo;
