import { DateRange } from "src/shared/components/datepicker/Datepicker";
import { Level } from "../report/components/levelpicker/LevelPicker";

export class ReportData {
  public insights: Insight[];
  public group_map: Map<string, string>;
  public accounts: { key: string; value: string }[];
  public campaigns: { group: string, inner: { key: string; value: string }[] }[];
  public ad_sets: { group: string, inner: { key: string; value: string }[] }[];
  public custom_conversions: { group: string, inner: { key: string; value: string }[] }[];

  constructor(rawData: RawReportData) {
    this.insights = rawData.map((item) => new Insight(item));
    this.group_map = new Map();
    this.campaigns = [];
    this.ad_sets = [];
    this.custom_conversions = [];

    let accounts: Map<string, { key: string; value: string }> = new Map();
    let campaigns: Map<string, { key: string; value: string }[]> = new Map();
    let ad_sets: Map<string, { key: string; value: string }[]> = new Map();
    let custom_conversions: Map<string, { key: string; value: string }[]> = new Map();

    this.insights.forEach((value, index) => {
      if (value.account) {
        this.group_map.set(value.account_id, value.account);
        accounts.set(value.account_id, { key: value.account_id, value: value.account });
      }

      if (value.campaign) {
        this.group_map.set(value.campaign_id, value.campaign);
        if (campaigns.has(value.account_id)) {
          campaigns.get(value.account_id)?.push({ key: value.campaign_id, value: value.campaign });
        } else {
          campaigns.set(value.account_id, [{ key: value.campaign_id, value: value.campaign }]);
        }
      }

      if (value.ad_set) {
        if (ad_sets.has(value.campaign_id)) {
          ad_sets.get(value.campaign_id)?.push({ key: value.ad_set_id, value: value.ad_set });
        } else {
          ad_sets.set(value.campaign_id, [{ key: value.ad_set_id, value: value.ad_set }]);
        }
      }

      if (value.custom_conversion) {
        if (ad_sets.has(value.campaign_id)) {
          custom_conversions.get(value.campaign_id)?.push(
            { key: value.custom_conversion_id, value: value.custom_conversion }
          );
        } else {
          custom_conversions.set(
            value.campaign_id,
            [{ key: value.custom_conversion_id, value: value.custom_conversion }]
          );
        }
      }
    });
    this.accounts = Array.from(accounts.values()).sort((a, b) =>
      a.value > b.value ? 1 : -1
    );

    campaigns.forEach((value, key) => this.campaigns.push({
      // @ts-ignore
      group: this.group_map.get(key), inner: value.sort((a, b) =>
        a.value > b.value ? 1 : -1
      )
    }));
    this.campaigns = this.campaigns.sort((a, b) =>
      a.group > b.group ? 1 : -1
    );

    ad_sets.forEach((value, key) => this.ad_sets.push({
      // @ts-ignore
      group: this.group_map.get(key), inner: value.sort((a, b) =>
        a.value > b.value ? 1 : -1
      )
    }));
    this.ad_sets = this.ad_sets.sort((a, b) =>
      a.group > b.group ? 1 : -1
    );

    custom_conversions.forEach((value, key) => this.custom_conversions.push({
      // @ts-ignore
      group: this.group_map.get(key), inner: value.sort((a, b) =>
        a.value > b.value ? 1 : -1
      )
    }));
    this.custom_conversions = this.custom_conversions.sort((a, b) =>
      a.group > b.group ? 1 : -1
    );
  }
}

type RawReportData = RawData[];

type RawData = {
  account_id: string;
  account: string;
  campaign_id: string;
  campaign: string;
  ad_set_id: string;
  ad_set: string;
  custom_conversion: string;
  ad_spend: number;
  clicks: number;
  conversions: number;
  conversions_value: number;
  impressions: number;
  reach: number;
};

export class Insight {
  public account_id: string;
  public account: string;
  public campaign_id: string;
  public campaign: string;
  public ad_set_id: string;
  public ad_set: string;
  public custom_conversion_id: string;
  public custom_conversion: string;
  public ad_spend: number;
  public clicks: number;
  public conversions: number;
  public conversions_value: number;
  public impressions: number;
  public reach: number;
  constructor(
   rawData: RawData
  ) {
    this.account_id = rawData.account_id;
    this.account = rawData.account;
    this.campaign_id = rawData.campaign_id;
    this.campaign = rawData.campaign;
    this.ad_set_id = rawData.ad_set_id;
    this.ad_set = rawData.ad_set;
    this.custom_conversion_id = rawData.campaign_id + rawData.custom_conversion;
    this.custom_conversion = rawData.custom_conversion;
    this.ad_spend = rawData.ad_spend;
    this.clicks = rawData.clicks;
    this.conversions = rawData.conversions;
    this.conversions_value = rawData.conversions_value;
    this.impressions = rawData.impressions;
    this.reach = rawData.reach;
  }
}

export type ReportContextType = {
  reportData: ReportData | null;
  previousData: ReportData | null;
  selectedAccounts: string[];
  selectableAccounts: { key: string; value: string }[] | null;
  setSelectedAccounts: ((values: string[]) => void) | null;
  setSelectableAccounts:
    | ((values: { key: string; value: string }[]) => void)
    | null;
  selectedCampaigns: string[];
  selectableCampaigns: { group: string, inner: { key: string; value: string }[] }[];
  setSelectedCampaigns: ((values: string[]) => void) | null;
  setSelectableCampaigns:
    | ((values: { group: string, inner: { key: string; value: string }[] }[]) => void)
    | null;
  selectedAdsets: string[];
  selectableAdsets: { group: string, inner: { key: string; value: string }[] }[] | null;
  setSelectedAdsets: ((values: string[]) => void) | null;
  setSelectableAdsets:
    | ((values: { group: string, inner: { key: string; value: string }[] }[]) => void)
    | null;
  selectedCustomConversions: string[];
  selectableCustomConversions: { group: string, inner: { key: string; value: string }[] }[];
  setSelectedCustomConversions: ((values: string[]) => void) | null;
  setSelectableCustomConversions:
    | ((values: { group: string, inner: { key: string; value: string }[] }[]) => void)
    | null;
  dateRange: DateRange | null;
  setDateRange: ((dateRange: DateRange) => void) | null;
  level: string;
  setLevel: ((level: Level) => void) | null;
  isLoading: boolean;
  setIsLoading: ((status: boolean) => void) | null;
};

export const defaultReportcontext: ReportContextType = {
  reportData: null,
  previousData: null,
  selectedAccounts: [],
  selectableAccounts: [],
  setSelectedAccounts: null,
  setSelectableAccounts: null,
  selectedCampaigns: [],
  selectableCampaigns: [],
  setSelectedCampaigns: null,
  setSelectableCampaigns: null,
  selectedAdsets: [],
  selectableAdsets: [],
  setSelectedAdsets: null,
  setSelectableAdsets: null,
  selectedCustomConversions: [],
  selectableCustomConversions: [],
  setSelectedCustomConversions: null,
  setSelectableCustomConversions: null,
  dateRange: null,
  setDateRange: null,
  level: Level.ACCOUNT,
  setLevel: null,
  isLoading: false,
  setIsLoading: null,
};
