import React from "react";

import FaqItems from "./components/FaqItems";
import Header from "src/app/landing/components/header/Header";

import DashboardImage from "static/images/landing/dashboard_photo.png";

import styles from "./Landing.scss";
import WhatWeDo from "./components/WhatWeDo";
import OurTeam from "./components/OurTeam";
import classNames from "classnames";
import Footer from "src/app/landing/components/Footer";

const CoverSection = () => {
  return (
    <div
      className={classNames(
        styles.paddedSection,
        styles.paddedSectionCenter,
        styles.paddedSectionLarge,
        styles.paddedSectionDarkBlue
      )}
    >
      <div className={styles.coverSection}>
        <div className={styles.intro}>
          <div className={styles.top}>
            Helping retailers grow using Point of Sale data
          </div>
          <div className={styles.bottom}>
            Omni Lightning is a tool that helps businesses utilize the data
            inside of their Point of Sale to drive more traffic to their store
          </div>
        </div>
        <span className={styles.image}>
          <img src={DashboardImage} alt="" />
        </span>
      </div>
    </div>
  );
};

const Landing = () => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <div>
        <CoverSection />
        <WhatWeDo />
        <OurTeam />
        <FaqItems />
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
