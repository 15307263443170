import { User } from "src/shared/types/user";

const USER_STORAGE_KEY = "user";
const CREATE_TOKEN_STORAGE_KEY = "create_token";

export type CreateToken = {
  token: string;
};
class AuthService {
  private storage: Storage;

  public constructor() {
    this.storage = window.sessionStorage;
  }

  public setUser(user: User) {
    this.storage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  }

  public getUser(): User {
    const savedValue = this.storage.getItem(USER_STORAGE_KEY);
    return savedValue ? JSON.parse(savedValue) : null;
  }

  public setCreateToken(createToken: CreateToken) {
    this.storage.setItem(CREATE_TOKEN_STORAGE_KEY, JSON.stringify(createToken));
  }

  public getCreateToken(): CreateToken {
    const savedValue = this.storage.getItem(CREATE_TOKEN_STORAGE_KEY);
    return savedValue ? JSON.parse(savedValue) : null;
  }

  public clearUser() {
    this.storage.removeItem(USER_STORAGE_KEY);
    this.storage.removeItem(CREATE_TOKEN_STORAGE_KEY);
  }
}

export default new AuthService();
