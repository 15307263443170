// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container__StandardLayout__apLbI{display:flex;min-height:100%;height:min-content}.standardLayout__StandardLayout__EieuT{background-color:#f9fafc;min-height:100%;display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/shared/components/standard-layout/StandardLayout.scss","webpack://./src/styles/_settings.scss"],"names":[],"mappings":"AAEA,kCACE,YAAA,CACA,eAAA,CACA,kBAAA,CAGF,uCACE,wBCIU,CDHV,eAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":["@import \"src/styles/common\";\n\n.container {\n  display: flex;\n  min-height: 100%;\n  height: min-content;\n}\n\n.standardLayout {\n  background-color: $off-white;\n  min-height: 100%;\n  display: flex;\n  justify-content: center;\n}\n","// Colour Palette\n// ---------\n\n// Main colors\n$dark-blue: #0f113e;\n$blue: #0c539b;\n$light-blue: #0995F8;\n$blue-gray: #6186a0;\n$green: #76f3d4;\n$yellow: #ffC509;\n\n$black: #101827;\n$white: #fff;\n$off-white: #f9fafc;\n\n$light-gray: #f1f2f4;\n$medium-light-gray: #d4d9de;\n$gray: #b7c0c8;\n$medium-dark-gray: #9aa7b1;\n$dark-gray: #6f8190;\n\n$red: #d90000;\n\n:export {\n  black: $black;\n  dark-blue: $dark-blue;\n  light-blue: $blue;\n  red: $red;\n  dark-grey: $dark-gray;\n  medium-dark-grey: $medium-dark-gray;\n  grey: $gray;\n  medium-light-grey: $medium-light-gray;\n  light-grey: $light-gray;\n  green: $green;\n  yellow: $yellow;\n  white: $white;\n}\n\n// font weights\n$light: 300;\n$normal: 400;\n$bold: 700;\n$dark: 900;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#101827",
	"dark-blue": "#0f113e",
	"light-blue": "#0c539b",
	"red": "#d90000",
	"dark-grey": "#6f8190",
	"medium-dark-grey": "#9aa7b1",
	"grey": "#b7c0c8",
	"medium-light-grey": "#d4d9de",
	"light-grey": "#f1f2f4",
	"green": "#76f3d4",
	"yellow": "#ffc509",
	"white": "#fff",
	"container": "container__StandardLayout__apLbI",
	"standardLayout": "standardLayout__StandardLayout__EieuT"
};
export default ___CSS_LOADER_EXPORT___;
