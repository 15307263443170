import {
  conversion,
  audience,
  client,
  klaviyo,
} from "../shared/types/run-types";

export const createRunType = async (
  url: string,
  conversion: conversion,
  token: string
) => {
  await fetch(process.env.REACT_APP_BACKEND_URL + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(conversion),
  })
    .then((response) => {
      console.log(response.json())
    })
    .catch((error) => {
      console.log(error);
    });
};



