export const getAllowList = async (currentUser) => {
  const client = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${currentUser?.readToken}`,
    },
  });
  const clientData = await client.json();
  return clientData;
};

 const buildPosRunTypeData = (POS) => {
  let newData = {};

  POS.POS.forEach((pos) => {
    pos.data.forEach((data) => {
      if (data.type === "text") {
        newData[pos.title + " " + data.title] = "";
        newData[pos.title + " " + `${data.title} Error`] = false;
      } else if (data.type === "number") {
        newData[pos.title + " " + data.title] = 0;
        newData[pos.title + " " + `${data.title} Error`] = false;
      }
    });
  });
  return newData;
};

// method to build out the runTypeData based on the runTypes json file
export const buildRunTypeData = (RunTypes, POS) => {
  let newData = {};
  const posData = buildPosRunTypeData(POS);

  RunTypes.run_types.forEach((runType) => {
    newData[runType.title] = {};

    newData[runType.title]["url"] = runType.url;
    runType.data.forEach((data) => {
      if (data.type === "text") {
        newData[runType.title][data.title] = "";
      } else if (data.type === "select") {
        if (!data.options) return;
        newData[runType.title][data.title] = data.options[0];
      } else if (data.type === "array") {
        if (!data.accepts) return;
        newData[runType.title][data.title] = [];
      } else if (data.type === "number"){
        if (!data.accepts) return;
        newData[runType.title][data.title] = 0;
      } else {
        if (!data.accepts) return;
        newData[runType.title][data.title] = [];
      }
      newData[runType.title][`${data.title} Error`] = false;
    });
  });
  newData["New Client"] = { ...newData["New Client"], ...posData };
  return newData;
};

export const buildJson = (name: string, runTypeData, state, clients, POS) => {
  const posNames = POS.POS.map((pos) => pos.title);

  let data = runTypeData[name];
  let json = {};

  // loop through keys and values of datar
  // only add it if it does not inlcude "Error"
  // add the keys in lowercase and if there is a space replace it with an underscore
  for (let [key, value] of Object.entries(data)) {
    // check to make sure the key does not include any of the pos names

    if (!posNames.some((pos) => key.includes(pos)) && key !== "url") {
      if (!key.includes("Error")) {
        // if includes facebook make it fb
        if (key.includes("Facebook")) {
          key = key.replace("Facebook", "FB");
        }
        // Don't add if it is empty
        if (value !== "") {
          // replace spaces with underscores
          json[key.toLowerCase().replace(/ /g, "_")] = value;
        }
      }
    } else continue;
  }
  return json;
};

export const combineClientJson = (posJson, clientJson) => {
  // add posJson at the key POSData which is an array of objects
  delete clientJson["POS"];
  clientJson["pos"] = [posJson];
  // add the clientJson to the runTypeData
  return clientJson;
};

export const buildPOSJson = (name: string, POS: string, runTypeData) => {
  let data = runTypeData[name];
  let json = {
    id: POS,
  };
  // loop through keys and values of data
  // only add it if it does not inlcude "Error"
  // add the keys in lowercase and if there is a space replace it with an underscore
  // get rid of POS from the beginning of the key
  // add the data to json[POS].data
  for (let [key, value] of Object.entries(data)) {
    if (key.includes(POS) && !key.includes("Error")) {
      // remove POS from the beginning of the key
      let trimmedKey = key.replace(POS, "");
      // replace spaces with underscores
      // remove space from beginning of key
      trimmedKey = trimmedKey.trimStart();
      json[trimmedKey.toLowerCase().replace(/ /g, "_")] = value;
    }
  }
  return json;
};
