import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import useAuthService from "src/shared/hooks/useAuthService";

import styles from "./AuthButton.scss";

const ReportButton = () => {
  const { loginUser } = useAuthService();

  return (
    <button className={styles.button} onClick={loginUser}>
      <DashboardIcon />
    </button>
  );
};

export default ReportButton;
