import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { createRemoteJWKSet, jwtVerify } from "jose";

import authService from "src/services/auth-service";
import { useEffect } from "react";

const useAuthService = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const readToken = await getAccessTokenSilently({
          audience: "thunder",
          // scope: "read:dash_data",
        });

        const JWKS = createRemoteJWKSet(
          new URL("https://auth.omnilightning.com/.well-known/jwks.json")
        );
        const { payload: permissions } = await jwtVerify(readToken, JWKS, {});

        if (user) {
          authService.setUser({
            name: user.name as string,
            email: user.email as string,
            picture: user.picture as string,
            readToken: readToken,
            permissions,
          });
        }

        navigate("/report", { replace: true });
      } catch (error) {
        console.log(error);
      }
    };

    const accessToken = authService.getUser()?.readToken;

    if (user && !accessToken) {
      getAccessToken();
    }
  }, [user]);

  const loginUser = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}/report`,
    }).then(
      () => {},
      (reason) => {
        console.error(reason);
      }
    );
  };

  const logoutUser = () => {
    authService.clearUser();
    logout();
    navigate("/", { replace: true });
  };

  const redirectIfNotLoggedIn = () => {
    if (!(isAuthenticated || authService.getUser() || isLoading)) {
      navigate("/", { replace: true });
    }
  };
  const isUserPermissionTo = (view: string) => {
    const permissions = authService.getUser()?.permissions;

    if (!permissions) {
      return;
    }
    return permissions.permissions.includes(view);
  };

  const redirectIfNotPermitted = (view: string) => {
    if (!isUserPermissionTo(view)) {
      navigate("/", { replace: true });
    }
  };

  const createCreateToken = () => {
    useEffect(() => {
      const getCreateToken = async () => {
        const createToken = await getAccessTokenSilently({
          audience: "thunder",
          scope: "create:user",
        });

        const JWKS = createRemoteJWKSet(
          new URL("https://auth.omnilightning.com/.well-known/jwks.json")
        );
        await jwtVerify(createToken, JWKS, {});

        if (user) {
          authService.setCreateToken({ token: createToken });
        }
      };

      const createToken = authService.getCreateToken()?.token;

      if (user && !createToken) {
        getCreateToken();
      }
    }, [user]);
  };

  return {
    loginUser,
    logoutUser,
    isAuthenticating: isLoading,
    redirectIfNotPermitted,
    isUserLoggedIn: isAuthenticated,
    currentUser: authService.getUser() || {},
    redirectIfNotLoggedIn,
    isUserPermissionTo,
    permissions: authService.getUser()?.permissions || null,
    createCreateToken,
    createToken: authService.getCreateToken()?.token || null,
  };
};

export default useAuthService;
