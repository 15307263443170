import React, { useContext } from "react";

import {
  currencyFormatter,
  truncateString,
} from "src/shared/helpers/formatters";
import { ReportContext } from "../../Report";

import styles from "./Stats.scss";
import { CircularProgress } from "@mui/material";

import {
  Tooltip,
  BarChart,
  Bar,
  YAxis,
  XAxis,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { processFilter } from "./Filter";

const Stats = () => {
  const {
    reportData,
    selectedAccounts,
    selectedCampaigns,
    selectedAdsets,
    previousData,
    level,
  } = useContext(ReportContext);

  if (
    !(
      previousData &&
      selectedAccounts &&
      selectedCampaigns &&
      selectedAdsets &&
      level &&
      reportData
    )
  ) {
    return null;
  }

  const currentReportData = reportData.insights.filter((insight) => {
    if (level === "account") {
      return selectedAccounts.includes(insight.account_id)
    } else if (level == "campaign") {
      return selectedCampaigns.includes(insight.campaign_id)
    } else {
      return selectedAdsets.includes(insight.ad_set_id);
    }
  });

  const previousReportData = previousData.insights.filter((insight) => {
    if (level === "account") {
      return selectedAccounts.includes(insight.account_id)
    } else if (level == "campaign") {
      return selectedCampaigns.includes(insight.campaign_id)
    } else {
      return selectedAdsets.includes(insight.ad_set_id);
    }
  });

  const stats = processFilter({
    rawReportData: currentReportData,
    prevRawReportData: previousReportData,
    level
  })


  return (
    <div className={styles.stats}>
      <div className={styles.dashboard}>
        <IndividualStat
          title="Return on Ad Spend"
          value={stats.current.roas}
          secondary={stats.previous.roas}
        />

        <IndividualStat
          title="Sales Revenue"
          value={stats.current.conversions_value}
          secondary={stats.previous.conversions_value}
        />

        <Chart
          data={stats.current.conversion_data_sorted}
          title={
            "Conversion Value by " + level[0].toUpperCase() + level.slice(1)
          }
        />

        <IndividualStat
          title="Ad Spend"
          value={stats.current.ad_spend}
          secondary={stats.previous.ad_spend}
        />

        <IndividualStat
          title="Transactions"
          value={stats.current.conversions}
          secondary={stats.previous.conversions}
        />

        <Chart
          data={stats.current.ad_spend_data_sorted}
          title={"Ad Spend by " + level[0].toUpperCase() + level.slice(1)}
        />

        <IndividualStat
          title="Cost Per Sale"
          value={stats.current.cost_per_conversion}
          secondary={stats.previous.cost_per_conversion}
        />

        <IndividualStat
          title="Average Sale Value"
          value={stats.current.avg_conversion_value}
          secondary={stats.previous.avg_conversion_value}
        />

        <div className={styles.vanity}>
          <div className={styles.card}>
            <div className={styles.title}>Clicks</div>
            <div className={styles.primary}> {stats.current.clicks} </div>
            <div className={styles.secondary}>{stats.previous.clicks}</div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>Impressions</div>
            <div className={styles.primary}>{stats.current.impressions}</div>
            <div className={styles.secondary}>{stats.previous.impressions}</div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>Reach</div>
            <div className={styles.primary}> {stats.current.reach} </div>
            <div className={styles.secondary}>{stats.previous.reach}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

type IndividualStatProps = {
  title: React.ReactNode;
  value: React.ReactNode;
  secondary: React.ReactNode;
};

export const IndividualStat = (props: IndividualStatProps) => {
  const { isLoading } = useContext(ReportContext);

  const { title, value, secondary } = props;

  return (
    <div className={styles.card}>
      <div className={styles.title}> {title} </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={styles.primary}> {value} </div>
          <div className={styles.secondary}>{secondary}</div>
        </>
      )}
    </div>
  );
};

type ChartOptions = {
  name: string;
  value: number;
}[];

type ChartProps = {
  title: React.ReactNode;
  data: never[] | ChartOptions;
};

const rem_size = () => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
};

const Chart = (props: ChartProps) => {
  const { title, data } = props;
  // Inspired by https://stackoverflow.com/a/64264409
  const renderCustomizedLabel = (props) => {
    let { x, y, width, height, value } = props;
    value = currencyFormatter.format(value);
    const offset = value.toString().length;
    if (width + offset > x + 200) {
      return (
        <text
          x={x + width - 5}
          y={y + height - 7}
          fill={"#f9fafc"}
          textAnchor="end"
        >
          {value}
        </text>
      );
    }
    return (
      <text
        x={x + width + offset * (rem_size() / 1.8)}
        y={y + height - 7}
        fill={"#0f113e"}
        textAnchor="end"
      >
        {value}
      </text>
    );
  };

  return (
    <div className={styles.smile}>
      <div className={styles.chartTitle}>{title}</div>
      <div className={styles.test}>
        <ResponsiveContainer height={35 * data.length} width="100%">
          <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          >
            <XAxis dataKey="value" hide type="number" />
            <YAxis
              type="category"
              dataKey="name"
              width={200}
              tickFormatter={(value) => truncateString(value, 35)}
              tick={{ fontSize: "0.9rem" }}
            />
            <Tooltip
              formatter={(value) =>
                currencyFormatter.format(Number.parseFloat(value.toString()))
              }
            />
            <Bar dataKey="value" fill="#0c539b" isAnimationActive={false}>
              <LabelList
                dataKey="value"
                content={renderCustomizedLabel}
                position="right"
                style={{ fill: "white" }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Stats;
