import React from "react";
import ReportButton from "./ReportButton";
import LoginButton from "./LoginButton";
import useAuthService from "src/shared/hooks/useAuthService";
import CircularProgress from "@mui/material/CircularProgress";

const AuthenticationButton = () => {
  const { isUserLoggedIn, isAuthenticating } = useAuthService();

  if (isAuthenticating) {
    return <CircularProgress />;
  }
  return isUserLoggedIn ? <ReportButton /> : <LoginButton />;
};

export default AuthenticationButton;
