import { Link } from "react-router-dom";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import { useEffect, useState } from "react";
import Logo from "src/shared/components/logo/Logo";
import styles from "./LsToken.scss";

function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

const Handler = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const code = getQueryVariable("code");

  useEffect(() => {
    getLsToken(process.env.REACT_APP_BACKEND_URL + "/ls_token", {
      refresh: code,
    })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  if (loading) {
    return (
      <CardContent>
        <Typography
          style={{ fontSize: 24, color: "#1C215F", textAlign: "center" }}
        >
          Loading...
        </Typography>
      </CardContent>
    );
  }
  if (error) {
    return (
      <div>
        <CardContent>
          <Typography
            style={{ fontSize: 20, color: "#1C215F", textAlign: "center" }}
          >
            Oops {error.message}
          </Typography>
        </CardContent>
      </div>
    );
  }

  return <ul>{ThankYou()}</ul>;
};

const ThankYou = () => {
  return (
    <div>
      <CardContent>
        <Typography
          style={{ fontSize: 24, color: "#1C215F", textAlign: "center" }}
        >
          Thank you!
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "center", paddingTop: "30px" }}>
        <Link className={styles.link} to="/">
          <button className={styles.button}>Home</button>
        </Link>
      </CardActions>
    </div>
  );
};

const LsToken = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Card className={styles.card}>
          <Handler />
        </Card>
      </div>
    </div>
  );
};

export default LsToken;

const getLsToken = async (url, json) => {
  try {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    });
  } catch (error: any) {
    return error;
  }
};
