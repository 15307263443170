// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__App__mApNY{display:block;width:100%;min-height:100%}", "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA,iBACI,aAAA,CACA,UAAA,CACA,eAAA","sourcesContent":["@import 'src/styles/settings';\n\n.app {\n    display: block;\n    width: 100%;\n    min-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#101827",
	"dark-blue": "#0f113e",
	"light-blue": "#0c539b",
	"red": "#d90000",
	"dark-grey": "#6f8190",
	"medium-dark-grey": "#9aa7b1",
	"grey": "#b7c0c8",
	"medium-light-grey": "#d4d9de",
	"light-grey": "#f1f2f4",
	"green": "#76f3d4",
	"yellow": "#ffc509",
	"white": "#fff",
	"app": "app__App__mApNY"
};
export default ___CSS_LOADER_EXPORT___;
