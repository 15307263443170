import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import useAuthService from "src/shared/hooks/useAuthService";
import StandardLayout from "src/shared/components/standard-layout/StandardLayout";
import { useSelector } from "react-redux";
import { getDrawerOpenStatus } from "../state/clientSelector";
import { Box } from "@mui/material";
import apiService, { HTTPMethod } from "src/services/api-service";
import config from "config";

type AllowedList = {
  clients: Client[];
  viewers: string[];
};

type Client = {
  id: string;
  name: string;
};

export const CreateAccount = () => {
  const { redirectIfNotPermitted, createCreateToken, createToken } =
    useAuthService();

  const isDrawerOpen = useSelector(getDrawerOpenStatus);

  redirectIfNotPermitted("create:user");
  createCreateToken();

  const [state, setState] = useState<{
    account_type: string;
    viewer: string;
    email: string;
    name: string;
    successMessage: string;
    errorMessage: string;
    alert: string;
    isLoading: boolean;
    isError: boolean;
    allowList: AllowedList | null;
  }>({
    account_type: "Single",
    viewer: "",
    email: "",
    name: "",
    successMessage: "",
    errorMessage: "",
    alert: "",
    isLoading: false,
    isError: false,
    allowList: null,
  });

  const {
    account_type,
    viewer,
    email,
    name,
    isLoading,
    isError,
    successMessage,
    errorMessage,
  } = state;

  useEffect(() => {
    if (!state.allowList && createToken) {
      setState({
        ...state,
        isLoading: true,
      });
      try {
        apiService
          .fetch(`${config.reactAppBackendUrl}/account`, {
            preserveEndpoint: true,
            method: HTTPMethod.GET,
            headers: {
              Authorization: `Bearer ${createToken}`,
            },
          })
          .then((response: AllowedList) => {
            response.clients.sort((a, b) => (b.name > a.name ? -1 : 1));
            setState({
              ...state,
              allowList: response,
              isError: false,
              isLoading: false,
            });
          });
      } catch (error: any) {
        setState({
          ...state,
          isError: true,
          errorMessage: error,
          isLoading: false,
        });
      }
    }
  }, [createToken]);

  const handleChange = (event) => {
    if (event.target.name === "account_type") {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        viewer: "",
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async () => {
    // Reset
    setState({
      ...state,
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      isError: false,
    });

    if (!(state.name && state.viewer && state.email)) {
      let message = "";

      if (!state.viewer) {
        message = "Please select a viewer";
      } else if (!state.email) {
        message = "Please select the email address";
      } else if (!state.name) {
        message = "Please enter the name";
      }

      setState({
        ...state,
        errorMessage: message,
      });

      return;
    }
    let name = account_name(state.account_type, state.viewer, state.name, state.allowList);
    const data = {
      name: name,
      email: state.email,
      account_type: state.account_type,
      allowed: state.viewer,
    };
    const url = `${config.reactAppBackendUrl}/account`;

    try {
      setState({
        ...state,
        isLoading: true,
      });

      const apiResponse = await apiService.fetch(url, {
        preserveEndpoint: true,
        method: HTTPMethod.POST,
        headers: {
          Authorization: `Bearer ${createToken}`,
        },
        body: JSON.stringify(data),
      });

      setState({
        ...state,
        account_type: "Single",
        viewer: "",
        email: "",
        name: "",
        successMessage: apiResponse.message,
        alert: "success",
        isLoading: false,
        errorMessage: "",
      });
    } catch (error: any) {
      setState({
        ...state,
        errorMessage: error,
        alert: "error",
        isError: true,
      });
    }
  };

  const mainContent = (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        borderRadius: "10px",
        padding: "20px",
        width: "500px",
        height: "100%",
      }}
    >
      <FormControl>
        <InputLabel htmlFor="account_type">Account Type</InputLabel>
        <Select
          onChange={handleChange}
          name="account_type"
          id="account_type"
          label="Account_type"
          disabled={isLoading}
          error={isError}
          value={account_type}
        >
          <MenuItem key="Single" value="Single">
            Single
          </MenuItem>
          <MenuItem key="Agency" value="Agency">
            Agency
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="viewer">Viewer</InputLabel>
        <Select
          onChange={handleChange}
          name="viewer"
          id="viewer"
          label="Viewer"
          disabled={isLoading}
          error={isError}
          value={viewer}
        >
          {account_type === "Single" && [
            state.allowList?.clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.name}
              </MenuItem>
            )),
          ]}
          {account_type === "Agency" && [
            state.allowList?.viewers.map((view) => (
              <MenuItem key={view} value={view}>
                {view}
              </MenuItem>
            )),
          ]}
        </Select>
      </FormControl>

      <TextField
        onChange={handleChange}
        name="email"
        id="email"
        label="Email"
        value={email}
      />

      <TextField
        onChange={handleChange}
        name="name"
        id="name"
        label="Name"
        value={name}
      />

      <FormControl>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading || isError}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </FormControl>
      {errorMessage && <Box sx={{ color: "red", m: 2 }}>{errorMessage}</Box>}

      {successMessage && (
        <Box sx={{ color: "green", m: 2 }}>{successMessage}</Box>
      )}
    </Stack>
  );

  return (
    <StandardLayout mainContent={mainContent} isDrawerOpen={isDrawerOpen} />
  );
};

// This makes the user's name into "<company or group name> - <user's name>".
// That format makes it easier to find a user.
function account_name(
  account_type: string,
  viewer: string,
  name: string,
  allowList: AllowedList | null,
): string {
  if (account_type === "Agency") {
    return `${viewer} - ${name}`;
  } else {
    let client = allowList?.clients.find(c => c.id === viewer);
    // @ts-ignore
    return `${client.name} - ${name}`;
  }
}
