import React from "react";
import styles from "./../Landing.scss";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.social}>
        <div
          onClick={onClickUrl("https://www.facebook.com/omnidigitalgroup/")}
          className={styles.iconWrapper}
        >
          <FacebookIcon className={styles.icon} />
        </div>
        <div
          onClick={onClickUrl("https://www.instagram.com/omnidigitalgroup")}
          className={styles.iconWrapper}
        >
          <InstagramIcon className={styles.icon} />
        </div>
        <div
          onClick={onClickUrl(
            "https://www.linkedin.com/company/omni-digital-group"
          )}
          className={styles.iconWrapper}
        >
          <LinkedInIcon className={styles.icon} />
        </div>
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.text}>
          Omni Digital Group - 1101 5th St. 101 Coralville, IA 52241
        </div>
        <div className={styles.text}>Contact - 319-600-6262</div>
        <div className={styles.text}>Email - client@omnidigitalgroup.com</div>
      </div>
    </div>
  );
};

const onClickUrl = (url) => {
  return () => openInNewTab(url);
};
const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export default Footer;
