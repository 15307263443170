import { ReportData } from "src/app/client/state/reportTypes";

export function filterAccounts(
  values: string[],
  setSelectedAccounts: (values: string[]) => void,
  setSelectedCampaigns: (values: string[]) => void,
  setSelectableCampaigns: (values: { group: string, inner: { key: string; value: string }[] }[]) => void,
  setSelectedAdsets: (values: string[]) => void,
  setSelectableAdsets: (values: { group: string, inner: { key: string; value: string }[] }[]) => void,
  setSelectedCustomConversions: (values: string[]) => void,
  setSelectableCustomConversions: (values: { group: string, inner: { key: string; value: string }[] }[]) => void,
  reportData: ReportData
) {
  values.sort((a, b) => {
    // @ts-ignore
    return reportData.group_map.get(a) > reportData.group_map.get(b) ? 1 : -1
  })
  setSelectedAccounts(values);
  let selected_campaigns: Map<string, Map<string, { key: string; value: string }>> = new Map();
  let selected_ad_sets: Map<string, { key: string; value: string }[]> = new Map();
  let selected_cc: Map<string, { key: string; value: string }[]> = new Map();
  reportData.insights.forEach((insight) => {
    if (values.includes(insight.account_id)) {
      if (insight.campaign_id && insight.campaign) {
        if (selected_campaigns.get(insight.account_id)) {
          selected_campaigns.get(insight.account_id)?.set(
            insight.campaign_id,
            { key: insight.campaign_id, value: insight.campaign }
          );
        } else {
          selected_campaigns.set(
            insight.account_id,
            new Map([[insight.campaign_id, { key: insight.campaign_id, value: insight.campaign }]])
          );
        }

        if (insight.ad_set_id && insight.ad_set) {
          if (selected_ad_sets.get(insight.campaign_id)) {
            selected_ad_sets.get(insight.campaign_id)?.push({ key: insight.ad_set_id, value: insight.ad_set });
          } else {
            selected_ad_sets.set(insight.campaign_id, [{ key: insight.ad_set_id, value: insight.ad_set }]);
          }
        }

        if (insight.custom_conversion) {
          let group = insight.campaign_id;
          let cc = insight.custom_conversion;
          if (selected_cc.has(group)) {
            selected_cc.get(group)?.push({ key: insight.custom_conversion_id, value: cc });
          } else {
            selected_cc.set(group, [{ key: insight.custom_conversion_id, value: cc }]);
          }
        }
      }
    }
  });

  if (selected_campaigns.size > 0) {
    let selected: { group: string, inner: { key: string; value: string }[] }[] = [];
    selected_campaigns.forEach((value, key) => {
      let temp: { key: string; value: string }[] = [];
      value.forEach((value) => temp.push(value));
      selected.push({
        //@ts-ignore
        group: reportData.group_map.get(key), inner: temp.sort((a, b) =>
          a.value > b.value ? 1 : -1
        )
      });
    });
    selected.sort((a, b) => a.group > b.group ? 1 : -1)
    setSelectedCampaigns([...selected.map(({ inner }) => inner.map(({ key }) => key)).flat()]);
    setSelectableCampaigns(selected);
  }

  if (selected_ad_sets.size > 0) {
    let selected: { group: string, inner: { key: string; value: string }[] }[] = [];
    selected_ad_sets.forEach((value, key) => {
      selected.push({
        //@ts-ignore
        group: reportData.group_map.get(key), inner: value.sort((a, b) =>
          a.value > b.value ? 1 : -1
        )
      });
    });
    selected.sort((a, b) => a.group > b.group ? 1 : -1)
    setSelectedAdsets([...selected.map(({ inner }) => inner.map(({ key }) => key)).flat()]);
    setSelectableAdsets(selected);
  }


  if (selected_cc.size > 0) {
    let selected: { group: string, inner: { key: string; value: string }[] }[] = [];
    selected_cc.forEach((value, key) => {
      selected.push({
        //@ts-ignore
        group: reportData.group_map.get(key), inner: value.sort((a, b) =>
          a.value > b.value ? 1 : -1
        )
      });
    });
    selected.sort((a, b) => a.group > b.group ? 1 : -1)
    setSelectedCustomConversions([...selected.map(({ inner }) => inner.map(({ key }) => key)).flat()]);
    setSelectableCustomConversions(selected);
  } else {
    setSelectedCustomConversions([]);
    setSelectableCustomConversions([]);
  }
}

export function filterCampaigns(
  values: string[],
  setSelectedCampaigns: (values: string[]) => void,
  setSelectedAdsets: (values: string[]) => void,
  setSelectableAdsets: (values: { group: string, inner: { key: string; value: string }[] }[]) => void,
  setSelectedCustomConversions: (values: string[]) => void,
  setSelectableCustomConversions: (values: { group: string, inner: { key: string; value: string }[] }[]) => void,
  reportData: ReportData
) {
  setSelectedCampaigns(values);

  let selected_ad_sets: Map<string, { key: string; value: string }[]> = new Map();
  let selected_cc: Map<string, { key: string; value: string }[]> = new Map();

  reportData.insights.forEach((insight) => {
    if (values.includes(insight.campaign_id)) {
      if (insight.ad_set_id && insight.ad_set) {
        let group = insight.campaign_id;
        if (selected_ad_sets.has(group)) {
          selected_ad_sets.get(group)?.push({ key: insight.ad_set_id, value: insight.ad_set });
        } else {
          selected_ad_sets.set(group, [{ key: insight.ad_set_id, value: insight.ad_set }]);
        }
      }

      if (insight.custom_conversion) {
        let group = insight.campaign_id;
        let cc = insight.custom_conversion;
        if (selected_cc.has(group)) {
          selected_cc.get(group)?.push({ key: insight.custom_conversion_id, value: cc });
        } else {
          selected_cc.set(group, [{ key: insight.custom_conversion_id, value: cc }]);
        }
      }
    }
  });

  if (selected_ad_sets.size > 0) {
    let selected: { group: string, inner: { key: string; value: string }[] }[] = [];
    selected_ad_sets.forEach((value, key) => {
      selected.push({
        //@ts-ignore
        group: reportData.group_map.get(key), inner: value.sort((a, b) =>
          a.value > b.value ? 1 : -1
        )
      });
    });
    selected.sort((a, b) => a.group > b.group ? 1 : -1)
    setSelectedAdsets([...selected.map(({ inner }) => inner.map(({ key }) => key)).flat()]);
    setSelectableAdsets(selected);
  }

  if (selected_cc.size > 0) {
    let selected: { group: string, inner: { key: string; value: string }[] }[] = [];
    selected_cc.forEach((value, key) => {
      selected.push({
        //@ts-ignore
        group: reportData.group_map.get(key), inner: value.sort((a, b) =>
          a.value > b.value ? 1 : -1
        )
      });
    });
    selected.sort((a, b) => a.group > b.group ? 1 : -1)
    setSelectedCustomConversions([...selected.map(({ inner }) => inner.map(({ key }) => key)).flat()]);
    setSelectableCustomConversions(selected);
  } else {
    setSelectedCustomConversions([]);
    setSelectableCustomConversions([]);
  }
}
