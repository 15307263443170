// Create our number formatter.
export const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  notation: "standard",
});

// Create our number formatter.
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncateString = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};
