// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reportWrapper__Report__AO_g7{display:flex}.report__Report__ENnkM{background-color:#f9fafc;width:100%;max-width:2000px;height:auto}", "",{"version":3,"sources":["webpack://./src/app/client/report/Report.scss","webpack://./src/styles/_settings.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CAGF,uBACE,wBCMU,CDLV,UAAA,CACA,gBAAA,CACA,WAAA","sourcesContent":["@import \"src/styles/common\";\n\n.reportWrapper {\n  display: flex;\n}\n\n.report {\n  background-color: $off-white;\n  width: 100%;\n  max-width: 2000px;\n  height: auto;\n}\n","// Colour Palette\n// ---------\n\n// Main colors\n$dark-blue: #0f113e;\n$blue: #0c539b;\n$light-blue: #0995F8;\n$blue-gray: #6186a0;\n$green: #76f3d4;\n$yellow: #ffC509;\n\n$black: #101827;\n$white: #fff;\n$off-white: #f9fafc;\n\n$light-gray: #f1f2f4;\n$medium-light-gray: #d4d9de;\n$gray: #b7c0c8;\n$medium-dark-gray: #9aa7b1;\n$dark-gray: #6f8190;\n\n$red: #d90000;\n\n:export {\n  black: $black;\n  dark-blue: $dark-blue;\n  light-blue: $blue;\n  red: $red;\n  dark-grey: $dark-gray;\n  medium-dark-grey: $medium-dark-gray;\n  grey: $gray;\n  medium-light-grey: $medium-light-gray;\n  light-grey: $light-gray;\n  green: $green;\n  yellow: $yellow;\n  white: $white;\n}\n\n// font weights\n$light: 300;\n$normal: 400;\n$bold: 700;\n$dark: 900;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#101827",
	"dark-blue": "#0f113e",
	"light-blue": "#0c539b",
	"red": "#d90000",
	"dark-grey": "#6f8190",
	"medium-dark-grey": "#9aa7b1",
	"grey": "#b7c0c8",
	"medium-light-grey": "#d4d9de",
	"light-grey": "#f1f2f4",
	"green": "#76f3d4",
	"yellow": "#ffc509",
	"white": "#fff",
	"reportWrapper": "reportWrapper__Report__AO_g7",
	"report": "report__Report__ENnkM"
};
export default ___CSS_LOADER_EXPORT___;
