import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import styles from "./MultiSelect.scss";
import { ListSubheader } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
      width: 450
    }
  }
};

type MultipleSelectProps = {
  label: string;
  options: {
    key: string;
    value: string;
  }[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
};
const MultipleSelect = (props: MultipleSelectProps) => {
  const { label, options, selectedValues, onChange } = props;

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value }
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onOnlyClick = (
    event: React.MouseEvent<HTMLSpanElement>,
    value: string
  ) => {
    onChange([value]);
    event.preventDefault();
    event.stopPropagation();
  };

  const inputLabel = `${label} ${selectedValues.length} of (${options.length} selected)`;
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="multiselect">{inputLabel}</InputLabel>
      <Select
        labelId="multiselect"
        id="demo-multiple-checkbox"
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label={inputLabel} />}
        renderValue={(selected) => selected.map(key => options.find(opt => opt.key === key)?.value).join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem key={"Select all"}>
          <ListItemText
            style={{ textAlign: "center" }}
            primary="Select all"
            onClick={(event) => {
              event.stopPropagation();
              onChange(options.map((item) => item.key));
            }}
          />
        </MenuItem>
        {options.map((option) => {
          return (
            <MenuItem key={option.key} value={option.key}>
              <div className={styles.selectItem}>
                <Checkbox checked={selectedValues.indexOf(option.key) > -1} />
                <span className={styles.name}>{option.value}</span>
                <span
                  className={styles.only}
                  onClick={(event) => onOnlyClick(event, option.key)}
                >
                  Only
                </span>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;

type GroupedMultipleSelectProps = {
  label: string;
  options: { group: string, inner: { key: string; value: string }[] }[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
};
export const GroupedMultipleSelect = (props: GroupedMultipleSelectProps) => {
  const { label, options, selectedValues, onChange } = props;

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value }
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onOnlyClick = (
    event: React.MouseEvent<HTMLSpanElement>,
    value: string
  ) => {
    onChange([value]);
    event.preventDefault();
    event.stopPropagation();
  };

  let length = options.map(({ inner }) => inner).flat().length;

  const inputLabel = `${label} ${selectedValues.length} of (${length} selected)`;
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="multiselect">{inputLabel}</InputLabel>
      <Select
        labelId="multiselect"
        id="demo-multiple-checkbox"
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label={inputLabel} />}
        renderValue={
          (selected) => selected
            .map(key => options
              .map(({ inner }) => inner)
              .flat()
              .find(opt => opt.key === key)?.value
            )
            .join(", ")
        }
        MenuProps={MenuProps}
      >
        <MenuItem key={"Select all"}>
          <ListItemText
            style={{ textAlign: "center" }}
            primary="Select all"
            onClick={(event) => {
              event.stopPropagation();
              onChange(options.map(({ inner }) => inner.map((item) => item.key)).flat());
            }}
          />
        </MenuItem>
        {options.map(({ group, inner }) => {
          return [
            <ListSubheader>{group}</ListSubheader>,
            inner.map((option) => {
              return (
                <MenuItem key={option.key} value={option.key}>
                  <div className={styles.selectItem}>
                    <Checkbox checked={selectedValues.indexOf(option.key) > -1} />
                    <span className={styles.name}>{option.value}</span>
                    <span
                      className={styles.only}
                      onClick={(event) => onOnlyClick(event, option.key)}
                    >
                      Only
                    </span>
                  </div>
                </MenuItem>
              );
            })
          ];
        })}
      </Select>
    </FormControl>
  );
};