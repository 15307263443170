import React, { useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import useAuthService from "src/shared/hooks/useAuthService";
import StandardLayout from "src/shared/components/standard-layout/StandardLayout";
import { useSelector } from "react-redux";
import { getDrawerOpenStatus } from "../state/clientSelector";
import { Box } from "@mui/material";
import runtypes from "./RunTypes.json";
import { MultiInput } from "./MultiInput";
import POS from "./POS.json";
import OutlinedInput from "@mui/material/OutlinedInput";

import Chip from "@mui/material/Chip";

// import API wrapper methods for each run type
import { createRunType } from "src/services/run-type-service";

import {
  getAllowList,
  buildRunTypeData,
  buildJson,
  buildPOSJson,
  combineClientJson
} from "./utils";

import {
  Client,
  Input,
  AllowedList,
  RunType
} from "src/shared/types/run-types";

export const RunTypes = () => {
  const [state, setState] = useState<{
    run_type: string;
    viewer: string;
    email: string;
    name: string;
    successMessage: string;
    errorMessage: string;
    alert: string;
    isLoading: boolean;
    isError: boolean;
    allowList: AllowedList | null;
  }>({
    run_type: "New Custom Conversion",
    viewer: "",
    email: "",
    name: "",
    successMessage: "",
    errorMessage: "",
    alert: "",
    isLoading: false,
    isError: false,
    allowList: null
  });

  const {
    run_type,
    viewer,
    email,
    name,
    isLoading,
    isError,
    successMessage,
    errorMessage
  } = state;

  const { redirectIfNotPermitted, currentUser } = useAuthService();

  useEffect(() => {
    redirectIfNotPermitted("create:job");
  }, []);

  const isDrawerOpen = useSelector(getDrawerOpenStatus);

  const [companyName, setCompanyName] = React.useState<string[]>([]);

  const [allowList, setAllowList] = useState<AllowedList | null>(null);

  const RunTypes = runtypes as RunType;

  const [runTypeData, setRunTypeData] = useState<any>(
    buildRunTypeData(RunTypes, POS)
  );

  const restoreRunTypeData = () => {
    setRunTypeData(buildRunTypeData(RunTypes, POS));
  };

  useEffect(() => {
    getAllowList(currentUser).then((data: AllowedList) => {
      data.clients.sort((a, b) => (b.name > a.name ? -1 : 1));
      setAllowList(data);
    });
    return () => {
    };
  }, []);

  // Handles changes for multi-input forms
  const handleMultiChange = (event, fieldName) => {
    const {
      target: { value }
    } = event;
    const currentRunTypeData = runTypeData[state.run_type];
    // add the new value to the existing values at runtypedata[state.run_type][fieldName]
    currentRunTypeData[fieldName] = value;
    setRunTypeData({ ...runTypeData, [state.run_type]: currentRunTypeData });
    if (fieldName === "Customers") {
      setCompanyName(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  // handles change for run_type state
  const handleChange = (event) => {
    if (event.target.name === "account_type") {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        viewer: ""
      });
    } else {
      if (event.target.name === "run_type") {
        restoreRunTypeData();
      }
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  // function to handle data from input fields
  const handleRunTypeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRunTypeData({
      ...runTypeData,
      [state.run_type]: {
        ...runTypeData[state.run_type],
        [name]: value
      }
    });
  };

  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let num = parseInt(value);
    setRunTypeData({
      ...runTypeData,
      [state.run_type]: {
        ...runTypeData[state.run_type],
        [name]: num
      }
    });
  };

  // functon that is passed to the child MultiInput component that adds the value to the array at the key
  const addValues = (values, key) => {
    setRunTypeData({
      ...runTypeData,
      [state.run_type]: {
        ...runTypeData[state.run_type],
        [key]: values
      }
    });
  };

  const validateRunTypeData = () => {
    // check if all fields are filled out and if not set key + " Error" to true for all occurences where the value is empty
    let oldData = runTypeData[state.run_type];
    let errorCount = 0;
    const currentPOS = runTypeData[state.run_type]["POS"];

    // find the object in RunTypes that matches the run_type
    const currentRunType = RunTypes.run_types.find(
      (runType) => runType.title === state.run_type
    );

    // find fields that are empty and if they are set the error to true
    // if the field + " Error" is already true but the value is not empty then set the error to false
    for (const [key, value] of Object.entries(oldData)) {
      // find the current input field in the current run type
      const currentInput = currentRunType?.data.find(
        (input) => input.title === key
      );

      const isRequired = currentInput?.required;

      // check if the type is an array and if it is check if the length is 0
      if (Array.isArray(value) && value.length === 0 && isRequired) {
        oldData[key + " Error"] = true;
        errorCount++;
      }
      // check if the field is an array and if value is not empty then set the error to false
      else if (Array.isArray(value) && value.length !== 0 && !isRequired) {
        oldData[key + " Error"] = false;
      }
      // check if the type is a string and if it is check if the length is 0
      else if (value === "" && !key.includes("Error") && isRequired) {
        oldData[key + " Error"] = true;
        if (key.includes(currentPOS)) {
          errorCount++;
        }
      } else if (value !== "" && !key.includes("Error") && !isRequired) {
        oldData[key + " Error"] = false;
      } else {
        continue;
      }
    }
    // set the runTypeData to the new object
    setRunTypeData({
      ...runTypeData,
      [state.run_type]: oldData
    });
    // check if error count is 0 and if it is return true, else return false
    return errorCount === 0;
  };

  const handleSubmit = async () => {
    if (validateRunTypeData()) {
      if (state.run_type === "New Client") {
        let posJson = buildPOSJson(
          state.run_type,
          runTypeData[state.run_type]["POS"],
          runTypeData
        );
        let clientJson = buildJson(
          state.run_type,
          runTypeData,
          state,
          allowList?.clients,
          POS
        );
        await createRunType(
          runTypeData[state.run_type]["url"],
          // @ts-ignore
          combineClientJson(posJson, clientJson),
          currentUser.readToken
        )
          .then(() => {
            // set all of the error fields to false
            let oldData = runTypeData[state.run_type];
            for (const [key, value] of Object.entries(oldData)) {
              if (key.includes("Error")) {
                oldData[key] = false;
              }
            }

            setState({
              ...state,
              errorMessage: "",
              successMessage: "Successfully created a new run type",
              isLoading: false,
              isError: false
            });
          })
          .catch((err) => {
            setState({
              ...state,
              errorMessage: "Error:" + err,
              successMessage: "",
              isLoading: false,
              isError: true
            });
            return;
          });
      } else {
        await createRunType(
          runTypeData[state.run_type]["url"],
          // @ts-ignore
          buildJson(state.run_type, runTypeData, state, allowList?.clients, POS),
          currentUser.readToken
        )
          .then(() => {
            // set all of the error fields to false
            let oldData = runTypeData[state.run_type];
            for (const [key, value] of Object.entries(oldData)) {
              if (key.includes("Error")) {
                oldData[key] = false;
              }
            }

            setState({
              ...state,
              errorMessage: "",
              successMessage: "Succesfully created a new run type",
              isLoading: false,
              isError: false
            });
          })
          .catch((err) => {
            setState({
              ...state,
              errorMessage: "Error:" + err,
              successMessage: "",
              isLoading: false,
              isError: true
            });
            return;
          });
      }
    } else {
      return;
    }
    setState({
      ...state,
      errorMessage: "",
      successMessage: "Succesfully created a new run type",
      isLoading: false,
      isError: false
    });
  };

  const mainContent = (
    <Stack
      key={""}
      direction="column"
      spacing={2}
      sx={{
        borderRadius: "10px",
        padding: "20px",
        width: "500px",
        height: "100%"
      }}
    >
      <FormControl>
        <InputLabel htmlFor="run_type">Run Type</InputLabel>
        <Select
          onChange={handleChange}
          name="run_type"
          id="run_type"
          label="Run Type"
          disabled={isLoading}
          error={isError}
          value={run_type}
        >
          {runtypes.run_types.map((type) => (
            <MenuItem key={type.title} value={type.title}>
              {type.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {RunTypes.run_types.map((type: Input) => {
        if (type.title === run_type) {
          return type.data.map((input) => {
            if (input.type === "single-customer") {
              return (
                <FormControl key={input.title}>
                  <InputLabel htmlFor={input.title}>{input.title}</InputLabel>
                  <Select
                    onChange={(e) =>
                      handleRunTypeInput(
                        e as React.ChangeEvent<HTMLInputElement>
                      )
                    }
                    name={input.title}
                    id={input.title}
                    label={input.title}
                    disabled={isLoading}
                    error={isError}
                    value={runTypeData[run_type][input.title]}
                  >
                    {allowList?.clients &&
                      allowList?.clients.sort().map((client: Client) => {
                        return (
                          <MenuItem
                            key={client.name}
                            value={client.id}
                          >
                            {client.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              );
            } else if (input.type === "multi-customers") {
              return (
                <FormControl>
                  <InputLabel id="demo-multiple-chip-label">
                    Customers
                  </InputLabel>
                  <Select
                    key={input.title}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    error={runTypeData[run_type][input.title + " Error"]}
                    multiple
                    value={companyName}
                    onChange={(e) => handleMultiChange(e, "Customers")}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Customers"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {allowList?.clients.sort().map((client: Client) => (
                      <MenuItem
                        key={client.name}
                        value={client.name}
                      >
                        {client.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            } else if (input.type === "custom-audiences") {
              let client = allowList?.clients.find(
                (client: Client) => runTypeData["New Custom Audience"]["Client ID"] == client.id
              );
              // @ts-ignore
              return (
                <FormControl>
                  <InputLabel id="custom-audiences-label">
                    Custom Audiences
                  </InputLabel>
                  <Select
                    key={input.title}
                    labelId="custom-audiences-label"
                    id="custom-audiences-multi"
                    error={runTypeData[run_type][input.title + " Error"]}
                    multiple
                    value={runTypeData[run_type][input.title]}
                    onChange={(e) => handleMultiChange(e, "Custom Audiences")}
                    input={
                      <OutlinedInput
                        id="custom-audiences-select-multiple-chip"
                        label="Custom Audiences"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {
                      client?.suppliers.filter(
                        (supplier: string) => !client?.custom_audiences.includes(supplier)
                      ).flat().map((supplier: string) => (
                        <MenuItem
                          key={supplier}
                          value={supplier}
                        >
                          {supplier}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              );
            } else if (input.type === "custom-conversions") {
              let client = allowList?.clients.find(
                (client: Client) => runTypeData["New Custom Conversion"]["Client ID"] == client.id
              );
              // @ts-ignore
              return (
                <FormControl>
                  <InputLabel id="custom-conversions-label">
                    Custom Conversions
                  </InputLabel>
                  <Select
                    key={input.title}
                    labelId="custom-conversions-label"
                    id="custom-conversions-multi"
                    error={runTypeData[run_type][input.title + " Error"]}
                    multiple
                    value={runTypeData[run_type][input.title]}
                    onChange={(e) => handleMultiChange(e, "Custom Conversions")}
                    input={
                      <OutlinedInput
                        id="custom-conversions-select-multiple-chip"
                        label="Custom Conversions"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {
                      client?.suppliers.filter(
                          (supplier: string) => !client?.custom_conversions.includes(supplier)
                      ).flat().map((supplier: string) => (
                        <MenuItem
                          key={supplier}
                          value={supplier}
                        >
                          {supplier}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              );
            } else if (input.type === "text") {
              return (
                <TextField
                  key={input.title}
                  name={input.title}
                  label={input.title}
                  value={runTypeData[run_type][input.title]}
                  onChange={(e) =>
                    handleRunTypeInput(e as React.ChangeEvent<HTMLInputElement>)
                  }
                  disabled={isLoading}
                  error={runTypeData[run_type][input.title + " Error"]}
                />
              );
            } else if (input.type === "select") {
              if (input.title === "POS") {
                return (
                  <>
                    <FormControl key={input.title}>
                      <InputLabel htmlFor={input.title}>
                        {input.title}
                      </InputLabel>
                      <Select
                        key={input.title}
                        onChange={(e) =>
                          handleRunTypeInput(
                            e as React.ChangeEvent<HTMLInputElement>
                          )
                        }
                        name={input.title}
                        id={input.title}
                        label={input.title}
                        disabled={isLoading}
                        error={isError}
                        value={runTypeData[run_type][input.title]}
                      >
                        {input.options &&
                          input.options.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    {POS.POS.map((p) => {
                      // check if the title matches the value selected
                      if (p.title === runTypeData[run_type][input.title]) {
                        // return the component that matches the title
                        return p.data.map((x) => {
                          if (x.accepts == "number") {
                            return (
                              <TextField
                                key={x.title}
                                name={p.title + " " + x.title}
                                label={p.title + " " + x.title}
                                inputProps={{ inputMode: "numeric" }}
                                value={runTypeData[run_type][x.title]}
                                onChange={(e) => {
                                  handleNumberInput(
                                    e as React.ChangeEvent<HTMLInputElement>
                                  );
                                }}
                                onKeyDown={(event) => {
                                  if (!/^[0-9]+$/.test(event.key) && event.key !== "Backspace") {
                                    event.preventDefault();
                                  }
                                }}
                                disabled={isLoading}
                                error={
                                  runTypeData[run_type][
                                  p.title + " " + x.title + " Error"
                                    ]
                                }
                              />
                            );
                          } else {
                            return (
                              <TextField
                                key={x.title}
                                name={p.title + " " + x.title}
                                label={p.title + " " + x.title}
                                value={runTypeData[run_type][x.title]}
                                onChange={(e) =>
                                  handleRunTypeInput(
                                    e as React.ChangeEvent<HTMLInputElement>
                                  )
                                }
                                disabled={isLoading}
                                error={
                                  runTypeData[run_type][
                                  p.title + " " + x.title + " Error"
                                    ]
                                }
                              />
                            );
                          }
                        });
                      }
                    })}
                  </>
                );
              }
              return (
                <FormControl key={input.title}>
                  <InputLabel htmlFor={input.title}>{input.title}</InputLabel>
                  <Select
                    key={input.title}
                    onChange={(e) =>
                      handleRunTypeInput(
                        e as React.ChangeEvent<HTMLInputElement>
                      )
                    }
                    name={input.title}
                    id={input.title}
                    label={input.title}
                    disabled={isLoading}
                    error={isError}
                    value={runTypeData[run_type][input.title]}
                  >
                    {input.options &&
                      input.options.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              );
            } else if (input.type === "array") {
              return (
                <MultiInput
                  key={input.title}
                  title={input.title}
                  isError={runTypeData[run_type][input.title + " Error"]}
                  fieldName={input.title}
                  addValues={addValues}
                />
              );
            } else null;
          });
        }
      })}

      <FormControl>
        <LoadingButton
          key={run_type}
          loading={isLoading}
          disabled={isLoading || isError}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </FormControl>
      {errorMessage && <Box sx={{ color: "red", m: 2 }}>{errorMessage}</Box>}

      {successMessage && (
        <Box sx={{ color: "green", m: 2 }}>{successMessage}</Box>
      )}
    </Stack>
  );

  return (
    <StandardLayout mainContent={mainContent} isDrawerOpen={isDrawerOpen} />
  );
};
