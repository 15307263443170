import React, { useContext } from "react";
import classNames from "classnames";
import Datepicker from "src/shared/components/datepicker/Datepicker";
import MultipleSelect, { GroupedMultipleSelect } from "src/shared/components/multi-select/MultiSelect";
import { ReportContext } from "../../Report";
import LevelPicker, { Level } from "../levelpicker/LevelPicker";

import styles from "./ReportFilters.scss";
import { filterAccounts, filterCampaigns } from "src/shared/helpers/Filters";

const ReportFilters = () => {
  const {
    reportData,
    previousData,
    selectedAccounts,
    selectableAccounts,
    setSelectedAccounts,
    setSelectableAccounts,
    selectedCampaigns,
    selectableCampaigns,
    setSelectedCampaigns,
    setSelectableCampaigns,
    selectedAdsets,
    selectableAdsets,
    setSelectedAdsets,
    setSelectableAdsets,
    setSelectedCustomConversions,
    setSelectableCustomConversions,
    dateRange,
    setDateRange,
    level,
    setLevel,
    setIsLoading
  } = useContext(ReportContext);

  if (
    !(
      reportData &&
      previousData &&
      selectedAccounts &&
      selectableAccounts &&
      setSelectedAccounts &&
      setSelectableAccounts &&
      selectedCampaigns &&
      selectableCampaigns &&
      setSelectedCampaigns &&
      setSelectableCampaigns &&
      selectedAdsets &&
      selectableAdsets &&
      setSelectedAdsets &&
      setSelectableAdsets &&
      setSelectedCustomConversions &&
      setSelectableCustomConversions &&
      dateRange &&
      setDateRange &&
      level &&
      setLevel &&
      setIsLoading
    )
  ) {
    return null;
  }

  const onAccountsChange = (values: string[]) => {
    filterAccounts(
      values,
      setSelectedAccounts,
      setSelectedCampaigns,
      setSelectableCampaigns,
      setSelectedAdsets,
      setSelectableAdsets,
      setSelectedCustomConversions,
      setSelectableCustomConversions,
      reportData
    );
  };

  const onCampaignsChange = (values: string[]) => {
    filterCampaigns(
      values,
      setSelectedCampaigns,
      setSelectedAdsets,
      setSelectableAdsets,
      setSelectedCustomConversions,
      setSelectableCustomConversions,
      reportData
    );
  };

  const onLevelChange = (value: Level) => {
    setIsLoading(true);
    setLevel(value);
  };

  const filtersClasses = classNames(styles.selectors, styles.selectorsOpen);
  return (
    <div className={styles.reportFilters}>
      <div style={{ display: "flex" }}>
        <div className={styles.datePicker}>
          <Datepicker dateRange={dateRange} onChange={setDateRange} />
          <LevelPicker level={level} onChange={onLevelChange} />
        </div>
        <div className={filtersClasses}>
          <MultipleSelect
            label={"Account"}
            selectedValues={selectedAccounts}
            options={selectableAccounts}
            onChange={onAccountsChange}
          />
          {level == Level.CAMPAIGN || level == Level.ADSET ? (
            <GroupedMultipleSelect
              label={"Campaign"}
              selectedValues={selectedCampaigns}
              options={selectableCampaigns}
              onChange={onCampaignsChange}
            />
          ) : (
            <></>
          )}

          {level == Level.ADSET ? (
            <GroupedMultipleSelect
              label={"Ad Sets"}
              selectedValues={selectedAdsets}
              options={selectableAdsets}
              onChange={setSelectedAdsets}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportFilters;
